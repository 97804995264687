import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../..';
import { setCompareCourse } from '../../Redux/Reducers/comparecoursesReducers';
import CloseIconForCC from '../../assets/Images/CloseIconForCC.svg';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const CompareCourseModal = ({
    CourseInfo,
    CourseInfoChange,
    checkedStatus,
    checkedStatusChange,
}: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const [courses, setCourses] = useState([]);
    const { token } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );

    const compareSelectedCoursesFunc = async () => {
        // console.log(CourseInfo, 'CourseInfo');
        if (checkedStatus.length > 1) {
            const courseIds = CourseInfo.map(
                (course: any, i: number) => course.id
            );
            const courseTrainerId = CourseInfo.map(
                (course: any, i: number) => course.trainer_id
            );
            navigate('/comparing-course', {
                state: {
                    courseIds: courseIds,
                    courseTrainerId: courseTrainerId,
                },
            });
        } else
            toast.error(
                camelCaseConvertForErrorMessage('Select Minimum 2 Courses')
            );
    };

    const courseCloseButton = (status: any) => {
        const removeID = CourseInfo.filter(
            (idx: any) => idx['id'] !== status.id
        );
        CourseInfoChange([...removeID]);
        const removeIdForChecked = checkedStatus.filter(
            (idx: any) => idx !== status.id
        );
        checkedStatusChange([...removeIdForChecked]);
    };

    const clearAll = () => {
        checkedStatusChange([]);
        CourseInfoChange([]);
        dispatch(setCompareCourse({ cardState: false }));
    };

    return (
        <div className="flex flex-col">
            {/* <div className="w-full flex items-center justify-center bg-opacity-[0.86] fixed left-0">
                <p className="text-white w-[10%] h-[5%] bg-red-500">Collapse<span></span></p>
            </div> */}
            {/* update height from 35% to 45% */}
            <div className="h-[50%] w-full flex items-center justify-center bg-[#000000] bg-opacity-[0.86] fixed bottom-0 left-0 overflow-hidden z-[3]">
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between items-center gap-4 min-w-[220px]">
                        <h1 className="text-[12px] lg:text-[14px] 2xl:text-[16px] font-[400] text-white">
                            Compare({checkedStatus.length})
                        </h1>
                        <div className="flex items-center gap-4">
                            <p
                                className="text-white text-[12px] lg:text-[14px] 2xl:text-[16px] font-[400] cursor-pointer"
                                onClick={clearAll}
                            >
                                Clear
                            </p>
                            <button
                                className="bg-[#085BEB] p-2 text-white text-[12px] lg:text-[14px] 2xl:text-[16px] font-[400] rounded-[4px]"
                                onClick={compareSelectedCoursesFunc}
                            >
                                Compare
                            </button>
                        </div>
                    </div>
                    <div className="flex gap-2 justify-center">
                        {CourseInfo.map((status: any, index: number) => {
                            return (
                                <div
                                    className="py-0 flex justify-center w-[270px]"
                                    key={index}
                                >
                                    <div className="flex flex-col gap-1 px-4 py-2 bg-white relative w-[270px]">
                                        <img
                                            src={CloseIconForCC}
                                            alt="CloseIcon"
                                            className="absolute top-0 right-0 cursor-pointer w-[20px] lg:w-[22px] h-[20px] lg:h-[22px]"
                                            onClick={() =>
                                                courseCloseButton(status)
                                            }
                                        />
                                        <div className="mt-4">
                                            <img
                                                src={status['image']}
                                                alt={status['image']}
                                                className="w-full h-[80px]  object-contain"
                                            />
                                            <h1 className="mt-2 text-2xl font-[500] pl-3 capitalize">
                                                {status['title']}
                                            </h1>
                                            <p className="text-[#999999] text-[12px] lg:text-[14px] 2xl:text-[16px] font-[400] pl-3">
                                                {status['name']}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
