import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { parseFileNameFromStorageUrl } from './StorageUrlFileName';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';
import {
    deleteRecord,
    postRecord,
    readRecord,
} from '../../Services/backend/apiCalls';
import { nameFormattingForFileUpload } from './FileUploadNameFormat';
import { useAppSelector } from '../..';
import toast from 'react-hot-toast';
import { storage } from '../../Firebase/firebase';
import resumeuploadImg from '../../assets/Images/resumeuploadImg.svg';
import deleteDoc from '../../assets/Images/deleteDoc.svg';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { ModalBox } from '../UI_Modal/UI_Modal';

export const Document = ({
    documentShow,
    title,
    materials,
    setMaterials,
    id,
    isAdmin,
}: any) => {
    const params = useParams();
    const courseId = params?.id;

    // const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const [fileTitle, setFileTitle] = useState('');
    const [mat_id, setMat_Id] = useState(null);
    const [show, setShow] = useState(false);
    // const [documents, setDocuments] = useState([]);
    // const { token } = useAppSelector(
    //     ({ signin }: any) => signin['signinValue']
    // );
    const topicHeading = isAdmin ? 'Import Files' : 'References';

    const uploadResumeFile = async (e: any) => {
        const file = e.target.files[0];
        let fileName = file.name + '_' + id;
        const isFileAlreadyUploaded = materials.filter(
            (matFiles: any) => file.name === matFiles.name
        );
        if (isFileAlreadyUploaded.length) {
            return toast.error(
                camelCaseConvertForErrorMessage('File Already Uploaded')
            );
        }

        fileName = nameFormattingForFileUpload(fileName);
        // setResumeStatus("uploading...");
        toast.loading('uploading');
        const storageRef = ref(storage, `/coursematerials/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            (snapshot: any) => {},
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then(
                    async (url: any) => {
                        let materialUpload = await postRecord(
                            {
                                course_id: Number(id),
                                file_url: url,
                                name: file.name,
                            },
                            'coursematerials'
                        );
                        toast.dismiss();
                        // console.log({materialUpload});
                        if (materialUpload.status) {
                            setMaterials([
                                ...materials,
                                {
                                    name: file.name,
                                    file_url: url,
                                    id: materialUpload.data.id,
                                },
                            ]);
                            toast.success('Uploaded Successfully');
                        } else toast.error(materialUpload.message);
                    }
                );
            }
        );
    };

    const deleteCourseMaterial = async () => {
        const fileName = parseFileNameFromStorageUrl(url);
        const deleteStorageRef = ref(storage, `/coursematerials/${fileName}`);
        toast.loading('deleting');
        deleteObject(deleteStorageRef)
            .then(async () => {
                let deleteMaterial = await deleteRecord(
                    { id: mat_id },
                    'coursematerials'
                );
                toast.dismiss();
                if (deleteMaterial.status) {
                    toast.success(`${fileTitle} Deleted Successfully`);
                } else {
                    toast.error(deleteMaterial.message);
                }
                let newMaterials = materials.filter(
                    (mat: any) => mat.file_url !== url
                );
                setMaterials(newMaterials);
            })
            .catch((err) => {
                console.log('error occurred', err);
            });
    };

    const deleteModal = (flag: any) => {
        setShow(false);
        setUrl('');
        setFileTitle('');
        setMat_Id(null);
        if (flag === 'yes') {
            deleteCourseMaterial();
        } else {
            setShow(false);
        }
    };

    const openModal = (url: any, title: any, mat_id: any) => {
        setShow(true);
        setUrl(url);
        setFileTitle(title);
        setMat_Id(mat_id);
    };

  
    const getEditRequest = async () => {
        const CourseDetail = await readRecord(
            { courseId },
            'coursematerials'
        );
        if (CourseDetail.status) {
            // setDocuments(CourseDetail.data);
            setMaterials(CourseDetail.data);
        }
    };
    useEffect(() => {
        getEditRequest();
    }, []);
    return (
        <div className="w-11/12">
            <ModalBox show={show} fun={deleteModal} />
            <h1 className="text-[23px] font-[500]">{title}</h1>
            <div className="flex flex-col justify-evenly bg-white mt-10 p-4">
                <div className="">
                    <h1 className="text-[18px] font-[500] underline underline-offset-[15px] decoration-[#085BEB] decoration-2">
                        {topicHeading}
                    </h1>
                    <div className="border-b-2 mt-2"></div>
                </div>
                {isAdmin && (
                    <>
                        <input
                            className="h-[50px] border mt-5 placeholder:p-4"
                            id="resumeUpload"
                            hidden
                            name="resume_url"
                            accept={'application/pdf'}
                            multiple={false}
                            type={'file'}
                            placeholder={''}
                            onChange={uploadResumeFile}
                            onClick={(e: any) => {
                                e.target.value = null;
                            }}
                        />
                        <label
                            className="mt-4 flex justify-between border border-[#F4F4F4] outline-[#0074FC] p-2 cursor-pointer"
                            htmlFor="resumeUpload"
                        >
                            <div className="flex items-center">
                                <img
                                    alt=""
                                    src={resumeuploadImg}
                                    className="px-5"
                                />
                                {materials.length ? (
                                    <p>
                                        {materials.length}{' '}
                                        {materials.length === 1
                                            ? 'File'
                                            : 'Files'}
                                    </p>
                                ) : (
                                    <p>Select file</p>
                                )}
                            </div>
                            <p className="text-[#2580F5] text-[14px] font-[500] w-[150px] h-[50px] border-l-2 text-center py-4">
                                Attach Files
                            </p>
                        </label>
                    </>
                )}
                <div>
                    {materials.length > 0
                        ? materials.map((mat: any, index: number) => {
                              return (
                                  <CourseMaterialComp
                                      key={index}
                                      documentShow={documentShow}
                                      title={mat.name}
                                      deleteMat={openModal}
                                      url={mat.file_url}
                                      mat_id={mat.id}
                                  />
                              );
                          })
                        : !isAdmin && (
                              <div className="h-[10vh] flex items-center justify-center text-[#474748]">
                                  No Documents
                              </div>
                          )}
                </div>
            </div>
        </div>
    );
};

const CourseMaterialComp = ({
    documentShow,
    title,
    deleteMat,
    url,
    mat_id,
    key,
}: any) => {
    return (
        <div className="flex justify-between" key={key}>
            <div className="flex items-center px-7">
                <img alt="resumeUpload" src={resumeuploadImg} />
                <a
                    href={documentShow && url}
                    target="_blank"
                    className="px-5 hover:text-[#085BEB]"
                >
                    {title}
                </a>
            </div>
            {documentShow ? (
                <img
                    src={deleteDoc}
                    alt="deleteIcon"
                    className="w-[150px] h-[50px] py-4 cursor-pointer"
                    onClick={() => deleteMat(url, title, mat_id)}
                />
            ) : (
                <a
                    href={url}
                    target="_blank"
                    className="cursor-pointer text-[#2580F5] text-[14px] font-[500] w-[150px] h-[50px] text-center py-4"
                >
                    View
                </a>
            )}
        </div>
    );
};
