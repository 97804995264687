import { useEffect, useState } from 'react';
import { useAppSelector } from '../..';
import { useNavigate } from 'react-router-dom';
import { RoleCard } from './Role';
import { postRecord, updateRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { dateFormat } from './Role';
import Users from '../../assets/Images/Users.svg';
import dateImg from '../../assets/Images/dateImg.svg';
import { PulseLoader } from 'react-spinners';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { ModalBox } from '../UI_Modal/UI_Modal';
import DOMPurify from 'dompurify';

export const SuggestionCard = ({ appConfig, request }: any) => {
    const navigate = useNavigate();
    const { role, token } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    const [show, setShow] = useState(false);
    const [suggestID, setSuggestID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [suggestionCourses, setSuggestionCourses] = useState([] as any);
    const [alreadyfetcheditems, setAlreadyFetchedItems] = useState<any>([]);
    const userType = ['TI', 'FL'].includes(role) ? 'Trainee' : 'Trainer';
    const isTrainer = ['TI', 'FL'].includes(role);
    const checkDate = isTrainer ? 'Start at' : 'Posted at';
    // const { suggestion } = useAppSelector(({suggest}: any) => suggest['SuggestionData']);
    // console.log(suggestion, "suggestion");

    const suggestionForCourse = async () => {
        setLoading(true);
        const response = await postRecord(
            {},
            isTrainer ? 'biddingsuggestions' : 'directrequestsuggestions'
        );
        if (response.status) {
            setSuggestionCourses(response?.data);
            setLoading(false);
        } else toast.error(response.message);
    };

    useEffect(() => {
        suggestionForCourse();
    }, []);

    const getUserFields = (data: any) => {
        return {
            nameKey: isTrainer
                ? data[userType]['name']
                : data['profile']['name'],
            roleKey: isTrainer
                ? data[userType]['role']
                : data['profile']['role'],
            user_idKey: isTrainer
                ? data[userType]['user_id']
                : data['profile']['user_id'],
        };
    };

    const navigateTo = async (id: number, title: string, text: string) => {
        // const check = isTrainer&&text==='bid' ? text : 'course';
        const navPageDecide = isTrainer ? 'bid' : 'course';
        navigate(`/${navPageDecide}/view/${title}/${id}`);
    };

    if (loading) {
        return (
            <div className="flex w-full min-h-[450px] items-center justify-center">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }
    return (
        <div className="min-h-[500px] mt-10">
            {/* {request === 'suggestion' && (
            //   <p className="text-[13px] font-[400] text-[#5B5B5B] pt-1">
            //       Request you may be interested with
            //   </p>
          )} */}
            {suggestionCourses?.length === 0 ? (
                <div className="min-h-[200px] flex justify-center items-center text-[#085BEB] text-[18px] font-[500]">
                    No Suggestions Available
                </div>
            ) : (
                suggestionCourses &&
                suggestionCourses?.map((suggest: any, index: number) => {
                    let sanitizedDescription = DOMPurify.sanitize(
                        suggest.description
                    );
                    if (
                        isTrainer
                            ? true
                            : new Date().toISOString().split('T')[0] <=
                              suggest?.batches[0]?.batchDate
                    ) {
                        const suggestionValue = getUserFields(suggest);
                        return (
                            <div
                                key={index}
                                className="mt-4 w-full bg-white rounded-[8px] py-5 px-6 flex flex-col space-y-8"
                            >
                                <div className="flex gap-4 h-full">
                                    {!isTrainer && suggest['image'] && (
                                        <img
                                            src={suggest['image']}
                                            alt="SuggestImage"
                                            className="w-[220px]  object-contain"
                                        />
                                    )}
                                    <div className="flex flex-col space-y-3 relative w-full">
                                        <h1
                                            className="text-[#085BEB] text-[35px] font-[500] cursor-pointer"
                                            onClick={() =>
                                                navigateTo(
                                                    suggest['id'],
                                                    suggest.title,
                                                    'course'
                                                )
                                            }
                                        >
                                            {suggest['title']}
                                        </h1>
                                        {((isTrainer &&
                                            appConfig['bidding_suggestions'] ===
                                                'edit' &&
                                            appConfig['cms_action'] ===
                                                'edit') ||
                                            (!isTrainer &&
                                                appConfig[
                                                    'direct_request_suggestions'
                                                ] === 'edit' &&
                                                appConfig['cms_action'] ===
                                                    'edit')) && (
                                            <button
                                                className="cursor-pointer absolute right-0 top-0 bg-[#2975FD] rounded-[5px] p-2.5 px-3 text-white font-[400] text-[12px]"
                                                onClick={() =>
                                                    navigateTo(
                                                        suggest['id'],
                                                        suggest.title,
                                                        isTrainer
                                                            ? 'bid'
                                                            : 'course'
                                                    )
                                                }
                                            >
                                                {isTrainer
                                                    ? 'Place a Bid'
                                                    : 'Book Now'}
                                            </button>
                                        )}
                                        <div className="flex items-center">
                                            <p
                                                className="text-[17px] font-[500] cursor-pointer"
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'selectedCourseUserId',
                                                        JSON.stringify(
                                                            suggestionValue.user_idKey
                                                        )
                                                    );
                                                    navigate(
                                                        `/profile/view/${suggestionValue.nameKey}`
                                                    );
                                                }}
                                            >
                                                {suggestionValue.nameKey}
                                            </p>
                                            <RoleCard
                                                role={suggestionValue.roleKey}
                                            />
                                        </div>
                                        <h1 className="text-[12px] font-[300] text-[#5F5F5F]">
                                            {checkDate}
                                            <span className="ml-1 text-[#267DF8] text-[12px] font-[600]">
                                                {dateFormat(
                                                    suggest['createdAt']
                                                )}
                                            </span>
                                        </h1>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizedDescription,
                                            }}
                                            className="text-[16px] font-[300] text-[#5F5F5F] min-h-[70px] break-words"
                                        ></p>
                                    </div>
                                    {/* <UserRequestDetails suggest={suggest} /> */}
                                </div>
                            </div>
                        );
                    }
                })
            )}
        </div>
    );
};

const UserRequestDetails = ({ roleType, suggest }: any) => {
    const className = 'text-[#303030] text-[13px] xl:text-[15px] font-[400]';
    const { role } = useAppSelector(({ signin }: any) => signin['signinValue']);
    const countConversion = () => {
        let traineeType: string =
            ['TI', 'ST'].includes(role) || ['TI', 'ST'].includes(roleType)
                ? ' Student'
                : ' Employee';
        if (suggest['trainees_count'] > 1) {
            traineeType = traineeType + 's';
        }
        return suggest['trainees_count'] + traineeType;
    };
    return (
        <div className="flex">
            <Keyvalue
                icon={Users}
                alt="users"
                value={countConversion()}
                classType={className}
                addClass={'w-1/5'}
                index={'index'}
            />
            <Keyvalue
                icon={dateImg}
                alt="date"
                value={dateFormat(suggest['createdAt'])}
                addClass={'w-1/5'}
                classType={className}
            />
        </div>
    );
};

const Keyvalue = ({ icon, value, addClass, index, alt }: any) => {
    return (
        <div
            className={`flex gap-2 ${addClass} ${
                !index && 'border-l-[1px] px-3'
            }`}
        >
            <img
                src={icon}
                alt={alt}
                className={`${icon === Users ? 'w-[17px]' : 'w-[14px]'}`}
            />
            <p className="text-[14px] text-[#303030] font-[400]">{value}</p>
        </div>
    );
};
