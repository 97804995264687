import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../..';
import { isTrainer } from '../../Services/commonFunctions';
import { Card } from './Card';
import { CourseMenu } from '../CourseMenu/CourseMenu';
import arrow from '../../assets/Images/RightArrow.svg';
import { SuggestionCard } from './SuggestionCard';
import { OrderWiseSort } from '../Elements/OrderwiseSort';

export const RequestPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    // console.log(location, 'location');
    const selectedSection = params.requestorigin;
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [pageName, setPageName] = useState('');
    const [sorted, setSorted] = useState('Newest');
    const [{ role }, { appConfig }] = useAppSelector(
        ({ signin, appConfig }: any) => [
            signin['signinValue'],
            appConfig['configData'],
        ]
    );
    const userType = ['TI', 'FL'].includes(role) ? 'trainee' : 'trainer';
    const checkTrainer = ['TI', 'FL'].includes(role);

    useEffect(() => {
        if (selectedSection === 'training') {
            setStatus('upcoming');
        } else {
            setStatus('accepted');
        }
    }, [selectedSection]);

    useEffect(() => {
        const subText =
            userType === 'trainee' ? 'Ongoing Bids Just For You' : '';
        setPageName(
            selectedSection === 'booknow'
                ? 'Course Details'
                : selectedSection === 'bidding'
                ? 'Bidding Request'
                : selectedSection === 'training'
                ? 'Trainings'
                : `Courses Picked Based on Your Preferences ${subText}`
        );
    }, []);

    const optionsForSort = ['Newest', 'Oldest'];

    const myCourseChange = () => {
        setLoading(true);
        navigate('/request/mycourse', { state: 'typeForHeaderClick' });
    };
    const biddingChange = () => {
        setLoading(true);
        navigate('/request/bidding', { state: 'typeForHeaderClick' });
    };
    const directChange = () => {
        setLoading(true);
        navigate('/request/booknow', { state: 'typeForHeaderClick' });
    };
    const Training = () => {
        setLoading(true);
        navigate('/request/training', { state: 'typeForHeaderClick' });
    };

    return (
        <div className="bg-[#F6F7F8]">
            {appConfig && appConfig['cms_action'] !== 'off' && (
                <div className="w-11/12 mx-auto">
                    {state && state === 'typeForHeaderClick' && (
                        <div className="w-full sticky top-0 z-[1] bg-[#F6F7F8] py-4">
                            <div className="flex justify-between h-[42px]">
                                <div className="flex space-x-4 items-end">
                                    {isTrainer(role) && (
                                        <h1
                                            className={`text-[16px] font-[500] cursor-pointer ${
                                                selectedSection === 'mycourse'
                                                    ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                    : 'text-[#323232]'
                                            }`}
                                            onClick={myCourseChange}
                                        >
                                            My Courses
                                        </h1>
                                    )}
                                    {appConfig &&
                                        appConfig['bidding_action'] !==
                                            'off' && (
                                            <h1
                                                className={`text-[16px] font-[500] cursor-pointer ${
                                                    selectedSection ===
                                                    'bidding'
                                                        ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                        : 'text-[#323232]'
                                                }`}
                                                onClick={biddingChange}
                                            >
                                                {userType === 'trainee'
                                                    ? 'Current Bids'
                                                    : 'Bid Requests'}
                                            </h1>
                                        )}
                                    {appConfig &&
                                        appConfig['direct_request_action'] !==
                                            'off' && (
                                            <h1
                                                className={`text-[16px] font-[500] cursor-pointer ${
                                                    selectedSection ===
                                                    'booknow'
                                                        ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                        : 'text-[#323232]'
                                                }`}
                                                onClick={directChange}
                                            >
                                                Course Requests
                                            </h1>
                                        )}
                                    <h1
                                        className={`text-[16px] font-[500] cursor-pointer ${
                                            selectedSection === 'training'
                                                ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                : 'text-[#323232]'
                                        }`}
                                        onClick={Training}
                                    >
                                        Schedule
                                    </h1>
                                </div>
                                {selectedSection === 'mycourse' &&
                                    isTrainer(role) &&
                                    appConfig['cms_action'] === 'edit' && (
                                        <button
                                            className="bg-[#065FEF] w-[120px] h-[42px] rounded-[4px] text-white text-[13px] cursor-pointer"
                                            onClick={() => {
                                                navigate('/course/add');
                                            }}
                                        >
                                            Add Course
                                        </button>
                                    )}
                                {selectedSection === 'bidding' &&
                                    !isTrainer(role) &&
                                    appConfig['bidding_action'] === 'edit' &&
                                    appConfig['cms_action'] === 'edit' && (
                                        <button
                                            className="bg-[#065FEF] text-white w-[120px] h-[42px] rounded-[4px] font-[400] text-[13px]"
                                            onClick={() => navigate('/bid/add')}
                                        >
                                            New Bid
                                        </button>
                                    )}
                            </div>
                            <div className="border-b-2 mt-2"></div>
                        </div>
                    )}
                    <div className="bg-[#F6F7F8] mb-10">
                        {state && state !== 'typeForHeaderClick' && (
                            <>
                                <div className="flex gap-2 items-center mt-10 w-full mx-auto cursor-pointer">
                                    <img
                                        src={arrow}
                                        alt="ArrowForBack"
                                        className="rotate-180 w-[17px] h-[17px]"
                                        onClick={() => navigate('/dashboard')}
                                    />
                                    <p
                                        className="text-[18px] font-[400] text-[#3061F4]"
                                        onClick={() => navigate('/dashboard')}
                                    >
                                        Back
                                    </p>
                                </div>
                                {state !== 'typeForHeaderClick' &&
                                    selectedSection === 'suggestion' && (
                                        <p className="text-[18px] font-[500] absolute mt-4">
                                            {pageName}
                                        </p>
                                    )}
                            </>
                        )}
                        {state !== 'typeForHeaderClick' && ''}
                        {selectedSection === 'mycourse' && <CourseMenu />}
                        {selectedSection && selectedSection !== 'mycourse' && (
                            <div
                                className={`h-auto bg-[#F6F7F8] ${
                                    state !== 'typeForHeaderClick' && 'mt-4'
                                }`}
                            >
                                {selectedSection !== 'suggestion' ? (
                                    <Card
                                        status={status}
                                        request={selectedSection}
                                        origin={selectedSection}
                                        loading={loading}
                                        setLoading={setLoading}
                                        //
                                        checkTrainer={checkTrainer}
                                        pageName={pageName}
                                        setStatus={setStatus}
                                        paramsState={
                                            state !== 'typeForHeaderClick'
                                        }
                                        state={state}
                                        appConfig={appConfig}
                                    />
                                ) : (
                                    <SuggestionCard
                                        request={selectedSection}
                                        appConfig={appConfig}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export const RequestStatus = ({
    paramsState,
    pageName,
    status,
    request,
    setStatus,
    setLoading,
    checkTrainer,
}: any) => {
    const handleClick = (e: any) => {
        setStatus(e.target.title);
        setLoading(true);
    };
    return (
        <div className="w-full relative">
            {paramsState && (
                <p className="text-[18px] font-[500] absolute">{pageName}</p>
            )}
            {paramsState && <PageType text={pageName} request={request} />}
            {request !== 'suggestion' && (
                <div className="space-x-8 flex items-center justify-center ">
                    {' '}
                    <button
                        className={`${
                            status === 'accepted' || status === 'upcoming'
                                ? 'bg-[#E0FFF3] text-[#33BD87]'
                                : 'bg-white text-[#A8ABBB]'
                        } p-2.5 px-10 text-[14px] font-[500] rounded-[8px]`}
                        title={`${
                            request !== 'training' ? 'accepted' : 'upcoming'
                        }`}
                        onClick={handleClick}
                    >{`${
                        request !== 'training' ? 'Accepted' : 'Upcoming'
                    }`}</button>
                    <button
                        className={`${
                            status === 'pending'
                                ? 'bg-[#FFF3D2] text-[#ECB833]'
                                : 'bg-white text-[#A8ABBB]'
                        } p-2 px-10 text-[14px] font-[400] rounded-[8px]`}
                        title="pending"
                        onClick={handleClick}
                    >
                        In Progress
                    </button>
                    {request === 'bidding' && !checkTrainer ? null : (
                        <button
                            className={`${
                                status === 'rejected' || status === 'closed'
                                    ? // status === 'completed'
                                      'bg-[#FFDADA] text-[#FB5935]'
                                    : status === 'completed'
                                    ? 'bg-[#E7EFFF] text-[#2975FD]'
                                    : 'bg-white text-[#A8ABBB]'
                            } p-2.5 px-10 text-[14px] font-[400] rounded-[8px]`}
                            title={`${
                                request === 'booknow'
                                    ? 'rejected'
                                    : request === 'bidding'
                                    ? 'rejected'
                                    : 'completed'
                            }`}
                            onClick={handleClick}
                        >
                            {`${
                                request === 'booknow'
                                    ? 'Rejected'
                                    : request === 'bidding'
                                    ? 'Rejected'
                                    : 'Completed'
                            }`}
                        </button>
                    )}
                    {request === 'training' && (
                        <button
                            className={`${
                                status === 'Cancelled'
                                    ? 'bg-[#FFF3D2] text-[#ECB833]'
                                    : 'bg-white text-[#A8ABBB]'
                            } p-2 px-10 text-[14px] font-[400] rounded-[8px]`}
                            title="Cancelled"
                            onClick={handleClick}
                        >
                            Cancelled
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

const PageType = ({ text, request }: any) => {
    // console.log(request, 'request');
    return (
        <div className="absolute left-0">
            {/* <h1 className="text-[18px] font-[500]">{text}</h1> */}
            {request === 'suggestion' && (
                <p className="text-[13px] font-[400] text-[#5B5B5B] pt-1">
                    Request you may be interested with
                </p>
            )}
        </div>
    );
};
