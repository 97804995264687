import React from 'react';
import TrisektLogo from '../../assets/Images/trisektLogo.svg';
// import instaFooter from '../../assets/Images/instaFooter.svg';
// import fbFooter from '../../assets/Images/fbFooter.svg';
// import twitterFooter from '../../assets/Images/twitterFooter.svg';
import { useNavigate, Link } from 'react-router-dom';

// const styles = {
//     fontForTitle: 'text-2xl text-white',
//     fontStyle: 'text-[#9B9B9B] text-[14px]',
//     outerContainer: 'flex flex-col mt-2',
//     htag: 'text-white text-xl',
//     innerContainer: 'mt-2',
//     ptag: 'text-[#9B9B9B] mt-1 text-sm',
// };

export const Footer = () => {
    const navigate = useNavigate();
    // const title = ['Features', 'Our Courses', 'Community'];
    // const features = ['How it works', 'Why Us', 'Our Blog', 'Testimonial'];
    // const ourcourses = [
    //     'UI/UX design',
    //     'Development',
    //     'Graphic Design',
    //     'App Development',
    // ];
    // const community = [
    //     'Refer a Institute',
    //     'Limited Membership',
    //     'Free courses',
    // ];
    // const fontImage = [instaFooter, fbFooter, twitterFooter];

    return (
        <div className="py-4 pt-0 bg-black flex flex-col justify-around">
            <div className="w-9/12 mx-auto justify-between p-8 pr-1 pl-1 pt-4">
                <div className="flex flex-row justify-between row">
                    <div>
                        <img
                            src={TrisektLogo}
                            alt="logo"
                            className="cursor-pointer"
                            onClick={() => {
                                if (window.location.pathname === '/dashboard') {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                } else {
                                    navigate('/dashboard');
                                }
                            }}
                        />
                    </div>
                    <div className="mt-2 float-right justify-end">
                        <h1 className="text-lg text-white float-right">
                            Contact Us
                        </h1>
                        <p className="text-[#9B9B9B] text-16px">
                            admin@trisekt.com
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-9/12 mx-auto">
                <div className="border-b-0 border border-white"></div>
                <div className="flex justify-between text-sm mt-2">
                    <p className="text-[#9B9B9B] text-14px">
                        Copyrights © 2023 Trisekt. All rights reserved.
                    </p>

                    <a className="text-[#9B9B9B] text-14px"
                        href="/terms-condition"
                    >Terms & Conditions</a>
                </div>
            </div>
        </div>
    );
};
