import React, { useEffect, useState } from 'react';
import { Header } from './Components/Header/Header';
import { Footer } from './Components/Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '.';
import { setAppConfig } from './Redux/Reducers/appConfigReducers';
import toast from 'react-hot-toast';
import { readRecord } from './Services/backend/apiCalls';

export const Margin = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    })
  },[pathname])

  const [dashBoardLoading, setDashboardLoading] = useState(true);
  const [dashboardData,setDashboardData]=useState<any>({
      directRequests:[],
      biddings:[],
      trainings:[],
      statistics:{onGoing:0,completed:0}
    });

  const fetchDashBoardData=async()=>{
      setDashboardLoading(true);
      const { appConfig,status, directRequests, biddings, trainings, statistics, error, message }: any = await readRecord({},"dashboard",navigate);
      if (status) {
        dispatch(setAppConfig({ appConfig: appConfig }));
        setDashboardData({
            directRequests,
            biddings,
            trainings,
            statistics,
            appConfig,
        });
      } else toast.error(message);
      setDashboardLoading(false);
    }
  
    useEffect(()=>{
      let listen:boolean=true;
      if(listen){
        fetchDashBoardData();
      }
      return ()=>{
        listen=false;
      }
    }, [])
  return (
      <div className="h-screen">
          <Header />
          <div className="flex flex-col justify-between h-screen bg-[#F6F7F8]">
              {children}
              <Footer />
          </div>
      </div>
  );
}
