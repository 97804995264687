import React from 'react';
import { Input } from '../Elements/Input/Input';

export const BankDetails = ({ formik, isViewable }: any) => {
    return (
        <div>
            <h1 className="text-[#2580F5] text-[25px] font-[500] px-6">
            Bank Account Details
            </h1>
            <div className="grid grid-cols-2 gap-8 bg-white p-12 rounded-[30px] mt-4">
                <Input
                    name="account_holder_name"
                    placeholder="Enter account holder number"
                    label="Account Holder Name"
                    value={formik.values.account_holder_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.account_holder_name}
                    isTouched={formik.touched.account_holder_name}
                    impo="impo"
                />
                <Input
                    name="account_number"
                    placeholder="Enter account number"
                    label="Account Number"
                    value={formik.values.account_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.account_number}
                    isTouched={formik.touched.account_number}
                    impo="impo"
                />
                <Input
                    name="bank_name"
                    placeholder="Enter bank name"
                    label="Bank Name"
                    value={formik.values.bank_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.bank_name}
                    isTouched={formik.touched.bank_name}
                    impo="impo"
                />
                <Input
                    name="branch"
                    label="Branch"
                    placeholder="Enter account branch name"
                    value={formik.values.branch}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.branch}
                    isTouched={formik.touched.branch}
                    impo="impo"
                />
                <Input
                    name="ifsc_number"
                    placeholder="Enter IFSC code"
                    label="IFSC Number"
                    value={formik.values.ifsc_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.ifsc_number}
                    isTouched={formik.touched.ifsc_number}
                    impo="impo"
                />
            </div>
        </div>
    );
};
