import React from 'react';
import { RadioInput } from '../Elements/RadioInput/RadioInput';

export const OtherInfo = ({ formik, isViewable }: any) => {
    
    const setFunc = (title: any, val: any) => {
        formik.setFieldValue(title, val);
    }

    const filters = [
        {
            head: 'Do you provide Corporate Training ?',
            name: 'corporate_training',
            onChange: (title: any, val: any) => setFunc(title, val),
            value: formik.values.corporate_training,
        },
        {
            head: 'Do you provide Placement assistance ?',
            name: 'placement_assistance',
            onChange: (title: any, val: any) => setFunc(title, val),
            value: formik.values.placement_assistance,
        },
        {
            head: 'Do you provide Certificate assistance ?',
            name: 'certificate_assistance',
            onChange: (title: any, val: any) => setFunc(title, val),
            value: formik.values.certificate_assistance,
        },
        {
            head: 'Do you provide online training ?',
            name: 'is_online_training',
            onChange: (title: any, val: any) => setFunc(title, val),
            value: formik.values.is_online_training,
        },
        {
            head: 'Do you have Hands-On Lab Support ?',
            name: 'hands_on_lab_support',
            onChange: (title: any, val: any) => setFunc(title, val),
            value: formik.values.hands_on_lab_support,
        },
        {
            head: 'Do you have ISO Certification ?',
            name: 'iso_certification',
            onChange: (title: any, val: any) => setFunc(title, val),
            value: formik.values.iso_certification,
        },
    ];
    return (
        <div>
            <h1 className="text-[#2580F5] text-[25px] font-[500] px-6">
                Other Information
            </h1>
            <div className="grid grid-cols-2 gap-12 bg-white p-12 rounded-[30px] mt-4 border">
                {filters.map((data, i) => {
                    return (
                        <div className="grid gap-y-2 w-auto" key={i}>
                            <h1 className="text-[18px] font-[400]">
                                {data.head}
                            </h1>
                            <div className="flex space-x-4">
                                <RadioInput
                                    className="font-[500] text-[15px]"
                                    name={data.name}
                                    label="Yes"
                                    checked={data['value'] === true}
                                    type={'radio'}
                                    onChange={() => data.onChange(data['name'], true)}
                                />
                                <RadioInput
                                    className="font-[500] text-[15px]"
                                    name={data.name}
                                    label="No"
                                    checked={data['value'] === false}
                                    type={'radio'}
                                    onChange={()=>data.onChange(data['name'], false)}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
