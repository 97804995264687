import { useEffect } from 'react';
import { StarRating } from '../Elements/StarRating';
import sampleImageUpload from '../../assets/Images/sampleImageUpload.svg';
import { dateFormat, formatDateToDDMonthYYYY } from './DateFormat';
import { daysConversionAndSort } from '../../Services/commonFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoleCard } from '../CMS/Role';
import { bookedBatchTimeAllCourseHandler } from '../../Redux/Reducers/comparecoursesReducers';
import { useDispatch, useSelector } from 'react-redux';

import dompurify from 'dompurify';

export const CourseCard = ({
    individualCourse,
    loading,
    directRequestDetails,
    isDirectRequestPage,
    isTrainer,
    role,
}: any) => {
    const dispatch = useDispatch();
    const name = individualCourse.trainer_name;
    const navigate = useNavigate();
    const rating = Math.ceil(individualCourse.rating);
    let fee = individualCourse.fee;
    fee = fee && Number(fee).toLocaleString('en-IN');
    // const filterBatchTimings = individualCourse.batches?.filter((bt: any) => new Date(bt?.batchDate) >= new Date());
    // const batch_timings =
    //     (role==="TI" || role==="FL") ? (individualCourse && individualCourse?.batches?.map(
    //         (batch: any) => `${dateFormat(batch.batchDate)}(${batch.batchTime})`
    //     ) || []) : (filterBatchTimings && filterBatchTimings?.map(
    //         (batch: any) => `${dateFormat(batch.batchDate)}(${batch.batchTime})`
    //     ) || [])
    const batch_timings =
        (individualCourse &&
            individualCourse?.batches?.map(
                (batch: any) =>
                    `${dateFormat(batch.batchDate)}(${batch.batchTime})`
            )) ||
        [];
    const userType = isTrainer ? 'Trainee' : 'Trainer';
    const adjectiveText = isTrainer ? 'for' : 'by';
    const color: any = {
        rejected: 'text-[#FB5935]',
        pending: 'text-[#ECB833]',
    };
    const titleForDetails = ['Fees', 'Batch Timings', 'Duration'];
    const navigateToProfile = (userId: string) => {
        localStorage.setItem('selectedCourseUserId', JSON.stringify(userId));
        navigate('/profile/view/' + individualCourse?.trainer_name);
    };

    const sanitizedDescription = dompurify.sanitize(
        individualCourse.description || 'No description'
    ); // Sanitize description
    const sanitizedHighlights = dompurify.sanitize(
        individualCourse.highlights || 'No Highlights'
    ); // Sanitize highlights

    return (
        <div className="">
            <div className="min-h-[666px] h-auto bg-[#FBFBFB] flex flex-col items-center">
                <img
                    src={
                        individualCourse
                            ? individualCourse.image
                            : sampleImageUpload
                    }
                    alt="ViewCourse"
                    className="p-2 h-[310px] w-full object-contain"
                />
                <div className="flex flex-col justify-between w-11/12 mx-auto py-2">
                    <div className="flex justify-between w-full">
                        <h1 className="text-[23px] font-[500]">
                            {individualCourse.title}
                        </h1>
                        {!isDirectRequestPage && (
                            <div className="flex space-x-2 w-1/2  justify-end items-center">
                                <StarRating
                                    isClickable={false}
                                    rate={rating}
                                    noPointer={'noPointer'}
                                />
                                <p className="text-[#5F5F5F] text-16px font-[300]z mt-1">
                                    {rating}
                                </p>
                            </div>
                        )}
                    </div>
                    {!isDirectRequestPage && (
                        <div className="flex items-center space-x-4 py-2 w-full overflow-x-auto overflow-y-hidden relative">
                            {individualCourse.tags.map(
                                (tag: string, index: number) => (
                                    <p
                                        className="bg-[#F8F8F8] text-[#3061F4] font-[400] text-[12px] px-4 py-1 rounded-[18px]"
                                        key={index}
                                    >
                                        #{tag}
                                    </p>
                                )
                            )}
                        </div>
                    )}
                    {isDirectRequestPage && directRequestDetails ? (
                        <div
                            className={`flex items-center text-[14px] my-2 ${
                                color[directRequestDetails.status] ||
                                'text-[#00B89B]'
                            }`}
                        >
                            <span className="capitalize">
                                {directRequestDetails.status}
                            </span>
                            <span className="px-1">{adjectiveText}</span>
                            <span
                                className="font-[600] cursor-pointer"
                                onClick={() =>
                                    navigateToProfile(
                                        directRequestDetails[userType].user_id
                                    )
                                }
                            >
                                {directRequestDetails[userType].name}
                            </span>
                            <RoleCard
                                role={directRequestDetails[userType].role}
                            />
                        </div>
                    ) : (
                        <h1
                            className="text-[16px] text-[#636363] font-[500] mt-4 cursor-pointer capitalize"
                            onClick={() =>
                                navigateToProfile(individualCourse.trainer_id)
                            }
                        >
                            {name}
                        </h1>
                    )}
                    {/* <textarea
                        disabled
                        name="individualCourse.description"
                        className="mt-2 text-[16px] text-[#898989] font-[300] min-h-[30px] h-[180px] break-words bg-[#FBFBFB]"
                    >
                        {individualCourse.description === null
                            ? 'No description'
                            : individualCourse.description}
                    </textarea> */}

                    <div
                        className="mt-2 text-[16px] text-[#898989] font-[300] min-h-[30px]  break-words bg-[#FBFBFB]"
                        dangerouslySetInnerHTML={{
                            __html: sanitizedDescription,
                        }}
                    />

                    <div className="border-b-2 w-full mt-4"></div>
                    <h1 className="text-[16px] font-[400] text-[#3061F4] mt-4">
                        Highlights
                    </h1>
                    {/* <textarea
                        disabled
                        name="individualCourse.highlights"
                        className="mt-2 text-[16px] text-[#898989] min-h-[30px] h-[180px] font-[300] w-full bg-[#FBFBFB] break-words"
                    >
                        {[null, ''].includes(individualCourse.highlights)
                            ? 'No highlight'
                            : individualCourse.highlights}
                    </textarea> */}

                    <div
                        // name="individualCourse.highlights"
                        className="mt-2 text-[16px] text-[#898989] min-h-[30px]  font-[300] w-full bg-[#FBFBFB] break-words"
                        dangerouslySetInnerHTML={{
                            __html: sanitizedHighlights,
                        }}
                    ></div>

                    {/* <div className="mt-4">
                        <label className="text-[16px] font-[400] text-[#3061F4] mt-4">
                            Session
                        </label>
                        <p className="tracking-wide mt-2 text-[16px] text-[#898989] font-[300] min-h-[30px] w-full h-auto break-words">
                            {individualCourse
                                ? individualCourse.mode_of_teaching
                                    ? daysConversionAndSort(
                                          individualCourse.mode_of_teaching
                                              .session
                                      )
                                    : ''
                                : ''}
                        </p>
                    </div>
                    <div className="mt-8 w-full">
                        <table className="w-full">
                            <thead>
                                <tr className="text-start">
                                    {titleForDetails.map((data, index) => {
                                        return (
                                            <th
                                                key={index}
                                                className={`text-[12px] xl:text-[13px] 2xl:text-[14px] font-[400] text-[#555555] ${
                                                    index === 1
                                                        ? 'text-center'
                                                        : 'text-start'
                                                }`}
                                            >
                                                {data}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.name}>
                                        {individualCourse.currency_code} {fee}
                                    </td>
                                    <div className="flex items-center justify-center">
                                        {batch_timings.length > 0 ? (
                                            <td className="flex items-center justify-center w-[200px] lg:w-[320px] xl:w-[550px] overflow-x-auto snap-x snap-mandatory scrollBar scroll-smooth">
                                                {batch_timings.map(
                                                    (
                                                        bt: any,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <p
                                                                key={index}
                                                                className={`border-r-[1px] pr-1 text-[13px] font-[500] text-[#3061F4] text-center ${
                                                                    index !==
                                                                        0 &&
                                                                    'pl-3'
                                                                }`}
                                                            >
                                                                {bt}
                                                            </p>
                                                        );
                                                    }
                                                )}
                                            </td>
                                        ) : (
                                            <td>no batch timings</td>
                                        )}
                                    </div>
                                    <td className={styles.name}>
                                        {individualCourse.duration}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <SessionFeesBatchDurationComp
                        mode_of_teaching={individualCourse.mode_of_teaching}
                        duration={individualCourse.duration}
                        fees={individualCourse.currency_code + ' ' + fee}
                        batchTimings={batch_timings}
                        role={role}
                        discount={individualCourse.discount_percentage}
                    />
                    {/* directRequestDetails.isCustomized */}
                    {isDirectRequestPage && (
                        <div className="mt-8 mb-6">
                            <h4 className="text-[16px] font-[600] text-[#FB5935]">
                                Updated Info
                            </h4>
                            <div className="border-b-2 w-full mt-2"></div>
                            {directRequestDetails?.isCustomized ? (
                                <SessionFeesBatchDurationComp
                                    directRequestDetails={directRequestDetails}
                                    mode_of_teaching={
                                        directRequestDetails.mode_of_teaching
                                    }
                                    duration={directRequestDetails.duration}
                                    fees={
                                        directRequestDetails.currency_code +
                                        ' ' +
                                        Number(
                                            directRequestDetails.fee
                                        ).toLocaleString('en-IN')
                                    }
                                    batchTimings={`${dateFormat(
                                        directRequestDetails.date_of_training
                                    )}(${directRequestDetails.batch_timing})`}
                                    isCustomized={true}
                                />
                            ) : (
                                <SessionBookBatchDurationComp
                                    directRequestDetails={directRequestDetails}
                                />
                            )}
                        </div>
                    )}
                    {/* <div className="flex justify-between gap-4 mt-10 pb-2 border">
                        <div className="flex">
                            <div className="space-y-2">
                                <h1 className="text-[12px] xl:text-[13px] 2xl:text-[14px] font-[400] text-[#555555]">
                                    Fees
                                </h1>
                                <p className={styles.name}>
                                    {individualCourse.currency_code} {fee}
                                </p>
                            </div>
                            {batch_timings.length > 0 ? (
                                <div className="border-l-[1px] px-2 xl:px-6 2xl:px-8 space-y-2 relative">
                                    <h1 className={styles.postedData}>
                                        Batch Timings
                                    </h1>
                                    <div className="border w-[200px] absolute flex overflow-x-auto snap-x snap-mandatory scrollBar scroll-smooth">
                                        {batch_timings.map(
                                            (bt: any, index: number) => {
                                                return (
                                                    <p
                                                        key={index}
                                                        className={`border-r-[1px] pr-1 text-[13px] font-[500] text-[#3061F4] ${
                                                            index !== 0 &&
                                                            'pl-3'
                                                        }`}
                                                    >
                                                        {bt}
                                                    </p>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <p className="text-[#898989] text-[16px]">
                                    no batch timings
                                </p>
                            )}
                        </div>
                        <div className="border-l-[1px] px-2 xl:px-6 2xl:px-8 space-y-2">
                            <h1 className={styles.postedData}>Duration</h1>
                            <p className={styles.name}>
                                {individualCourse.duration}
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

const SessionFeesBatchDurationComp = ({
    mode_of_teaching,
    fees,
    batchTimings,
    duration,
    isCustomized,
    isDirectRequestPage,
    directRequestDetails,
    discount,
    role,
}: any) => {
    const dispatch = useDispatch();
    const cleanFees = parseFloat(fees.replace(/[^0-9.]/g, ''));

    const titleForDetails = ['Fees', 'Batch Timings', 'Duration'];
    useEffect(() => {
        dispatch(bookedBatchTimeAllCourseHandler(false));
    }, []);
    return (
        <>
            <div className="mt-4">
                <h1 className="text-[16px] font-[400] text-[#898989] mt-4">
                    <span className="  "> Mode: </span>
                    <span className="   ">
                        {' '}
                        {mode_of_teaching.mode === 'both'
                            ? 'online,offline'
                            : mode_of_teaching.mode}
                    </span>
                </h1>
                <label className="text-[16px] font-[400] text-[#898989] mt-4">
                    Session
                </label>
                <p
                    className={`tracking-wide min-h-[30px] w-full h-auto break-words ${`text-[13px] font-[500] ${
                        isCustomized ? 'text-[#FB5935]' : 'text-[#3061F4]'
                    }`}`}
                >
                    {mode_of_teaching
                        ? daysConversionAndSort(mode_of_teaching.session)
                        : ''}
                </p>
            </div>
            <div className="mt-4 w-full">
                <table className="w-full">
                    <thead>
                        <tr className="text-start">
                            {titleForDetails.map((data, index) => {
                                if (role === 'TI' && data === 'Fees') {
                                    return null; // Skip rendering this column
                                }
                                return (
                                    <th
                                        key={index}
                                        className={`text-[12px] xl:text-[13px] 2xl:text-[14px] font-[400] text-[#555555] ${
                                            index === 1
                                                ? 'text-center'
                                                : 'text-start'
                                        }`}
                                    >
                                        {data}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {role !== 'TI' ? (
                                <td
                                    className={`text-[13px] font-[500] ${
                                        isCustomized
                                            ? 'text-[#FB5935]'
                                            : 'text-[#3061F4]'
                                    }`}
                                >
                                    {/* {fees} */}
                                    INR{' '}
                                    {Number(cleanFees) -
                                        Number(cleanFees) * (discount / 100)}
                                </td>
                            ) : null}
                            {!isCustomized ? (
                                <div className="flex items-center justify-center">
                                    {batchTimings.length > 0 ? (
                                        <td className="flex items-center justify-center w-[200px] lg:w-[320px] xl:w-[500px] overflow-x-auto snap-x snap-mandatory scrollBar scroll-smooth">
                                            {batchTimings.map(
                                                (bt: any, index: number) => {
                                                    // console.log(bt, "bt", dateFormat(new Date()))
                                                    return (
                                                        <p
                                                            key={index}
                                                            className={`border-r-[1px] pr-1 text-[13px] font-[500] ${
                                                                isCustomized
                                                                    ? 'text-[#FB5935]'
                                                                    : 'text-[#3061F4]'
                                                            } text-center ${
                                                                index !== 0 &&
                                                                'pl-3'
                                                            }`}
                                                        >
                                                            {bt}
                                                        </p>
                                                    );
                                                }
                                            )}
                                        </td>
                                    ) : (
                                        <td>no batch timings</td>
                                    )}
                                </div>
                            ) : (
                                <td
                                    className={`w-[200px] lg:w-[320px] xl:w-[500px] text-[13px] font-[500] ${
                                        isCustomized
                                            ? 'text-[#FB5935]'
                                            : 'text-[#3061F4]'
                                    } text-center`}
                                >
                                    {batchTimings}
                                </td>
                            )}
                            <td
                                className={`text-[13px] font-[500] ${
                                    isCustomized
                                        ? 'text-[#FB5935]'
                                        : 'text-[#3061F4]'
                                }`}
                            >
                                {duration}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

const SessionBookBatchDurationComp = ({ directRequestDetails }: any) => {
    const titleForDetails = ['Fees', 'Batch Timings', 'Duration'];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(bookedBatchTimeAllCourseHandler(true));
    }, []);
    return (
        <>
            <div className="mt-2">
                <label
                    className={`text-[12px] xl:text-[13px] 2xl:text-[14px] font-[400] text-[#555555]`}
                >
                    Batch Timings
                </label>
                <p
                    className={`w-[200px] lg:w-[320px] xl:w-[500px] text-[13px] font-[500] text-[#FB5935]`}
                >{`${formatDateToDDMonthYYYY(
                    directRequestDetails.date_of_training.split('T')[0]
                )}(${directRequestDetails.batch_timing})`}</p>
            </div>
        </>
    );
};
