// // import React, { useState } from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import Login_Logo from '../../../assets/Images/Login_Logo.svg';
// // import arrow from '../../../assets/Images/RightArrow.svg';

// // const ResendEmailVerificationLink = () => {
// //     const [email, setEmail] = useState('');
// //     const navigate = useNavigate();

// //     const handleResendLink = () => {
// //         if (email) {
// //             navigate('/respond', {
// //                 state: {
// //                     email: email, // use the email from the input
// //                     redirect_url: 'http://localhost:3000/',
// //                 },
// //             });
// //         } else {
// //             alert('Please enter a valid email address');
// //         }
// //     };

// //     return (
// //         <div className="min-h-screen flex flex-col justify-center items-center w-full bg-gray-100">
// //             <img
// //                 src={Login_Logo}
// //                 alt="Login_Logo"
// //                 className="w-[190px] mb-10"
// //             />

// //             <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
// //                 <div
// //                     className="flex  justify-between items-center
// //                 "
// //                 >
// //                     <div className="flex gap-2 items-center     cursor-pointer">
// //                         <img
// //                             src={arrow}
// //                             alt="ArrowForBack"
// //                             className="rotate-180 w-[17px] h-[17px]"
// //                             onClick={() => navigate(-1)}
// //                         />
// //                         <p
// //                             className="text-[18px] font-[400] text-[#3061F4]"
// //                             onClick={() => navigate(-1)}
// //                         >
// //                             Back
// //                         </p>
// //                     </div>

// //                     <h2 className="text-3xl font-bold text-center text-blue-600 mb-6">
// //                         Resend Link
// //                     </h2>
// //                 </div>
// //                 <div className="mb-6">
// //                     <label
// //                         className="block text-gray-700 text-sm font-bold mb-2"
// //                         htmlFor="email"
// //                     >
// //                         Email
// //                     </label>
// //                     <input
// //                         id="email"
// //                         type="email"
// //                         placeholder="Enter your email"
// //                         className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
// //                         value={email}
// //                         onChange={(e) => setEmail(e.target.value)}
// //                         required
// //                     />
// //                 </div>
// //                 <button
// //                     onClick={handleResendLink}
// //                     className="bg-gradient-to-r from-blue-500 to-green-400 text-white font-bold py-2 px-4 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-300 w-full"
// //                 >
// //                     Next
// //                 </button>
// //             </div>
// //         </div>
// //     );
// // };

// // export default ResendEmailVerificationLink;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Login_Logo from '../../../assets/Images/Login_Logo.svg';
// import arrow from '../../../assets/Images/RightArrow.svg';

// const ResendEmailVerificationLink = () => {
//     const [email, setEmail] = useState('');
//     const navigate = useNavigate();

//     const handleResendLink = () => {
//         if (email) {
//             navigate('/respond', {
//                 state: {
//                     email: email,
//                     redirect_url: 'http://localhost:3000/',
//                 },
//             });
//         } else {
//             alert('Please enter a valid email address');
//         }
//     };

//     return (
//         <div className="min-h-screen flex flex-col justify-center items-center w-full bg-gray-100">
//             {/* Logo */}
//             <img src={Login_Logo} alt="Login_Logo" className="w-[160px] mb-8" />

//             {/* Form Container */}
//             <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
//                 {/* Back and Title */}
//                 <div className="flex items-center justify-between mb-6">
//                     <div
//                         className="flex items-center gap-2 cursor-pointer"
//                         onClick={() => navigate(-1)}
//                     >
//                         <img
//                             src={arrow}
//                             alt="Back"
//                             className="rotate-180 w-[16px] h-[16px]"
//                         />
//                         <span className="text-blue-600 text-lg font-medium">
//                             Back
//                         </span>
//                     </div>

//                     <h2 className="text-2xl font-semibold text-blue-600">
//                         Resend Link
//                     </h2>
//                 </div>

//                 {/* Input Field */}
//                 <div className="mb-6">
//                     <label
//                         htmlFor="email"
//                         className="block text-gray-700 text-sm font-semibold mb-2"
//                     >
//                         Email
//                     </label>
//                     <input
//                         id="email"
//                         type="email"
//                         placeholder="Enter your email"
//                         className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                     />
//                 </div>

//                 {/* Submit Button */}
//                 <button
//                     onClick={handleResendLink}
//                     className="w-full py-2 bg-gradient-to-r from-blue-500 to-green-400 text-white font-bold rounded-lg shadow-md hover:bg-gradient-to-l focus:outline-none focus:ring-2 focus:ring-green-300 transition duration-200"
//                 >
//                     Next
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default ResendEmailVerificationLink;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Login_Logo from '../../../assets/Images/Login_Logo.svg';
import arrow from '../../../assets/Images/RightArrow.svg';

const ResendEmailVerificationLink = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleResendLink = () => {
        if (email) {
            navigate('/respond', {
                state: {
                    email: email,
                    redirect_url: 'https://app.trisekt.com/login',
                    from: 'email-verification',
                },
            });
        } else {
            alert('Please enter a valid email address');
        }
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center w-full bg-gray-100 p-4">
            {/* Logo */}
            <img src={Login_Logo} alt="Login_Logo" className="w-[160px] mb-8" />

            {/* Form Container */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                {/* Back and Title */}
                <div className="flex justify-between items-center mb-6">
                    <div
                        className="flex items-center gap-2 cursor-pointer text-sm"
                        onClick={() => navigate(-1)}
                    >
                        <img
                            src={arrow}
                            alt="Back"
                            className="rotate-180 w-[12px] h-[16px]"
                        />
                        <span className="text-blue-600  font-medium">Back</span>
                    </div>

                    <h2 className="text-2xl font-semibold text-blue-600 flex-1 text-center text-xl ">
                        Resend Link
                    </h2>
                </div>

                {/* Input Field */}
                <div className="mb-6">
                    <label
                        htmlFor="email"
                        className="block text-gray-700 text-sm font-semibold mb-2"
                    >
                        Email
                    </label>
                    <input
                        id="email"
                        type="email"
                        placeholder="Enter your email"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                {/* Submit Button */}
                <button
                    onClick={handleResendLink}
                    className=" w-full bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-3 text-[12px] flex items-center justify-center font-[400] text-white rounded-md"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ResendEmailVerificationLink;
