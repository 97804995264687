import React from 'react';
import { Input } from '../Elements/Input/Input';
import { Dropdown } from '../Elements/Dropdown/Dropdown';
import { Cities } from '../SignupUserDetails/SignupUserDetails';
import { FreetextDropdown } from '../Elements/FreetextDropdown';

export const ContactInfo = ({
    isViewable,
    role,
    formik,
    email,
    CityInfo,
    search,
    dropdownVal,
    places,
    selectedInputValue,
}: any) => {
    return (
        <div>
            <h1 className="text-[#2580F5] text-[25px] font-[500] px-6">
                {role === 'TI' ? 'Branch' : 'Contact'} Information
            </h1>
            <div className="bg-white p-12 rounded-[30px] mt-4 space-y-4">
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                    <Input
                        name="contact_number"
                        disabled={isViewable}
                        label="Contact Number"
                        value={formik.values.contact_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.contact_number}
                        isTouched={formik.touched.contact_number}
                        impo="impo"
                    />
                    <Input
                        name="email"
                        disabled={true}
                        label="Email"
                        value={email}
                        impo="impo"
                    />
                    <Input
                        name="country"
                        label="Country"
                        disabled={true}
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.country}
                        isTouched={formik.touched.country}
                        impo="impo"
                    />
                    <Input
                        name="state"
                        label="State"
                        disabled={true}
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.state}
                        isTouched={formik.touched.state}
                        impo="impo"
                    />
                    <Dropdown
                        label="City"
                        Cities={Cities}
                        name="city"
                        disabled={isViewable}
                        val={formik.values.city}
                        onChange={(e: any) => {
                            formik.handleChange(e);
                            CityInfo(e);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.errors.city}
                        isTouched={formik.touched.city}
                    />
                </div>
                <div className="relative">
                    <Input
                        name="address_line"
                        label="Address"
                        role={role}
                        disabled={isViewable}
                        value={formik.values.address_line}
                        onChange={(e: any) => {
                            formik.handleChange(e);
                            search(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.errors.address_line}
                        isTouched={formik.touched.address_line}
                        impo="impo"
                    />
                    {dropdownVal && (
                        <FreetextDropdown
                            places={places}
                            onClick={selectedInputValue}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
