import React, { useState, useRef } from 'react';
import { createSearchParams, Navigate, useNavigate } from 'react-router-dom';
import Users from '../../assets/Images/Users.svg';
import Cal from '../../assets/Images/Cal.svg';
import TargetIcon from '../../assets/Images/TargetIcon.svg';
import editActive from '../../assets/Images/editActive.svg';
import editInActive from '../../assets/Images/editInActive.svg';
import courseImageSample from '../../assets/Images/courseImageSample.svg';
import PhoneIcon from '../../assets/Images/PhoneIcon.svg';
import RightArrow from '../../assets/Images/RightArrow.svg';
import { ProgressBar } from '../Elements/Progressbar';
import { StarRating } from '../Elements/StarRating';
import edit_select from '../../assets/Images/edit_select.svg';
import edit_unselect from '../../assets/Images/edit_unselect.svg';
import rs from '../../assets/Images/rs.svg';
import rsForDR from '../../assets/Images/rsForDR.svg';
import grrupees from '../../assets/Images/grrupees.svg';
import moment from 'moment';
import './card.css';
import { useAppSelector } from '../..';
import { updateRecord } from '../../Services/backend/apiCalls';
import { toast } from 'react-hot-toast';
import { RoleCard } from '../CMS/Role';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { ModalBox } from '../UI_Modal/UI_Modal';
import DOMPurify from 'dompurify';

const statusColor: any = {
    accepted: 'text-[#00B89B]',
    closed: 'text-[#FB5935]',
    failed: 'text-[#FB5935]',
    pending: 'text-[#ECB833]',
    rejected: 'text-[#FB5935]',
};

export const Card = ({
    traineeInfo,
    bid_id,
    dr_id,
    checkIsDirectRequest,
    appConfigDr,
    appConfigBd,
    appConfig,
    user_id,
    order_id,
    id,
    status,
    isTrainee,
    userRole,
    cardColor,
    title,
    course_id,
    cardType,
    userName,
    role,
    date,
    courseDescription,
    fee,
    date_of_training,
    trainees_count,
    phoneNumber,
    currency_code,
    bidAmount,
    endDate,
    isPaymentDone,
    updateDirectRequest,
    image,
    topBid,
    trainees_count: batch_size,
    updateTraining,
    reviewStatus,
}: any) => {
    // const { appConfig } = useAppSelector(
    //     ({ appConfig }: any) => appConfig['configData']
    // );
    const sanitizedDescription = DOMPurify.sanitize(courseDescription);

    const cardStyle = `${cardColor} flex flex-col justify-around p-4 text-[14px] snap-start`;
    const textTypeOne =
        cardType === 'DR' && isTrainee && status === 'accepted'
            ? 'Accepted by'
            : status === 'pending'
            ? 'Pending by'
            : 'Rejected by';
    const directStatus = cardType === 'DR' ? 'Requested on' : '';
    const bidStatus =
        cardType === 'BD' && status === 'accepted'
            ? 'Accepted on'
            : 'Quoted on';
    const courseStatus =
        cardType === 'CTP' && status === 'upcoming'
            ? 'Start on'
            : cardType === 'CTP' && status === 'pending'
            ? 'Started on'
            : 'Completed on';
    const suggestionStatus = !isTrainee ? 'Start on' : 'Posted on';
    const directRequestValues: any = {
        fee,
        date_of_training,
        trainees_count,
        phoneNumber,
        role,
        userRole,
    };
    const { token } = useAppSelector((state) => state.signin.signinValue);
    const navigate = useNavigate();

    // Trainer conditions
    const TrainerAndCTPAndProgress =
        cardType === 'CTP' && !isTrainee && status === 'pending';
    const CTPAndProgress = cardType === 'CTP' && status === 'pending';
    const CTP = cardType === 'CTP';
    const Suggest = cardType === 'suggest';

    // Trainee conditions
    const TraineeAndCTPAndCompleted =
        cardType === 'CTP' && isTrainee && status === 'completed';
    const TraineeAndSuggestion = cardType === 'suggest' && isTrainee;
    const showEdit = ['DR', 'BD'].includes(cardType) && isTrainee;
    const canEditCard =
        ['BD'].includes(cardType) && isTrainee && status === 'pending';

    // const bidAmountText=status==="rejected"?"Accepted Bid":
    // console.log({ cardType });

    const totalRating =
        reviewStatus &&
        reviewStatus.reduce((sum: any, rating: any) => sum + rating.rating, 0);
    const averageRating =
        reviewStatus && reviewStatus?.length > 0
            ? totalRating / reviewStatus.length
            : 0;
    // console.log(totalRating, averageRating, typeof averageRating, '11');

    const navRef = useRef<any>(false);

    const userTypeCondition: any = {
        TI: {
            name: 'Institute',
            color: 'bg-[#D3F9EB]',
            text: 'text-[#33BD87]',
        },
        FL: {
            name: 'Freelancer',
            color: 'bg-[#E7EFFF]',
            text: 'text-[#267DF8]',
        },
        ST: {
            name: 'Student',
            color: 'bg-[#E7EFFF]',
            text: 'text-[#2975FD]',
        },
        CO: {
            name: 'Corporate',
            color: 'bg-[#E0FFF3]',
            text: 'text-[#33BD87]',
        },
    };

    const userTypeValue = role && userTypeCondition[role]['name'];
    const userTypeStyle = `${role && userTypeCondition[role]['color']} ${
        role && userTypeCondition[role]['text']
    } ml-2 py-1 px-4 text-[10px] rounded-[15px]`;

    const cancelUpcomingCourse = (order_id: any, id: number) => {
        // console.log(order_id, id, "id Data");
        // console.log('cancelling upcoming course');
        navigate('/cancellation', { state: { order_id: order_id, id: id } });
    };

    const navigateTo = () => {
        if (cardType === 'BD') {
            navigate(`/bid/view/${title}/${id}`);
        }
    };

    const courseTitleNavigation = (event: any, title: string, id: number) => {
        if (cardType === 'suggest') {
            isTrainee
                ? appConfigDr && navigate(`/course/view/${title}/${id}`)
                : appConfigBd && navigate(`/bid/view/${title}/${id}`);
        } else if (cardType === 'CTP') {
            checkIsDirectRequest
                ? navigate({
                      pathname: `/booknow/${title}/${course_id}`,
                      search: createSearchParams({ dr_id: dr_id }).toString(),
                  })
                : navigate(`/bid/view/${title}/${bid_id}`);
        } else if (cardType === 'DR') {
            navigate({
                pathname: `/booknow/${title}/${course_id}`,
                search: createSearchParams({ dr_id: id.toString() }).toString(),
            });
        }
    };

    return (
        <div className="snap-start">
            {TraineeAndSuggestion && (
                <img
                    alt="courseImage"
                    className="w-[330px] h-[200px] p-1 bg-white object-contain"
                    src={image || courseImageSample}
                />
            )}

            <div className={cardStyle} onClick={navigateTo}>
                <div
                    className={`w-[292px] ${
                        TraineeAndSuggestion ? 'space-y-3' : 'space-y-4'
                    }`}
                >
                    {cardType === 'DR' && appConfig && (
                        <AcceptOrReject
                            acceptFunction={() =>
                                updateDirectRequest(id, 'accepted')
                            }
                            rejectFunction={() =>
                                updateDirectRequest(id, 'rejected')
                            }
                            isTrainee={isTrainee}
                        />
                    )}
                    {cardType === 'DR' && isTrainee && (
                        <StatusForDirectRequest
                            id={id}
                            status={status}
                            user={userName}
                            title={title}
                            textTypeOne={textTypeOne}
                            userTypeStyle={userTypeStyle}
                            userTypeValue={userTypeValue}
                            isPaymentDone={isPaymentDone}
                            config={appConfig}
                            userId={user_id}
                            courseId={course_id}
                        />
                    )}
                    {cardType === 'BD' &&
                        ((['pending', 'rejected'].includes(status) &&
                            !isTrainee) ||
                            (['pending', 'accepted'].includes(status) &&
                                isTrainee)) && (
                            <TopBids
                                // userTypeStyle={userTypeStyle}
                                // userTypeValue={userTypeValue}
                                // userName={userName}

                                cardtype="BD"
                                traineeInfo={traineeInfo}
                                topBidderDetails={topBid}
                                status={status}
                                requote={status === 'pending'}
                                isTrainee={isTrainee}
                                config={appConfig}
                                title={title}
                            />
                        )}
                    {cardType === 'CTP' &&
                        status === 'upcoming' &&
                        isTrainee && (
                            <CancelButton
                                onClick={cancelUpcomingCourse}
                                order_id={order_id}
                                id={id}
                            />
                        )}
                    {TrainerAndCTPAndProgress && (
                        <CompleteButton
                            id={id}
                            updateTraining={updateTraining}
                            isDr={checkIsDirectRequest}
                        />
                    )}
                    {!isTrainee && (
                        <div>
                            <h3
                                className="font-[600] text-[26px] shrinkToTwoLines shrinkToOneLine cursor-pointer "
                                onClick={(event) =>
                                    courseTitleNavigation(event, title, id)
                                }
                            >
                                {title}
                            </h3>
                            <div className="w-[40px] mt-1 h-[2px] rounded-full bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                        </div>
                    )}
                    {isTrainee && cardType !== 'DR' && (
                        <div>
                            <h3
                                className="font-[600] text-[26px] leading-[39px] shrinkToTwoLines shrinkToOneLine cursor-pointer"
                                onClick={(event) =>
                                    courseTitleNavigation(event, title, id)
                                }
                            >
                                {title}
                            </h3>
                            <div className="w-[40px] mt-1 h-[2px] rounded-full bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                        </div>
                    )}
                    {(!isTrainee ||
                        (isTrainee &&
                            cardType !== 'DR' &&
                            cardType !== 'BD')) && (
                        <div className="space-y-3">
                            <div className="flex items-center">
                                <p
                                    className="font-[600] text-[#5F5F5F] cursor-pointer"
                                    onClick={(event) => {
                                        localStorage.setItem(
                                            'selectedCourseUserId',
                                            JSON.stringify(user_id)
                                        );
                                        navigate(`/profile/view/${userName}`);
                                    }}
                                >
                                    {userName}
                                </p>
                                <p className={userTypeStyle}>{userTypeValue}</p>
                            </div>
                            <div className="flex">
                                {((cardType !== 'BD' && !isTrainee) ||
                                    (cardType === 'BD' && !isTrainee) ||
                                    cardType !== 'BD') && (
                                    <p className="text-[#5F5F5F] font-[400]">
                                        {cardType === 'BD'
                                            ? bidStatus
                                            : cardType === 'CTP'
                                            ? courseStatus
                                            : cardType === 'DR'
                                            ? directStatus
                                            : suggestionStatus}{' '}
                                        <span
                                            className={`font-[600] ${
                                                (CTP || Suggest) &&
                                                ' text-[#2975FD]'
                                            }`}
                                        >
                                            {dateFormat(date)}
                                        </span>
                                    </p>
                                )}
                                {cardType === 'suggest' && !isTrainee && (
                                    <div className="ml-1 text-[#5F5F5F] font-[400]">
                                        | Batch size{' '}
                                        <span className="text-[#267DF8] font-[600]">
                                            {batch_size}
                                        </span>
                                    </div>
                                )}
                                {cardType === 'CTP' &&
                                    status === 'upcoming' && (
                                        <div className="ml-1 text-[#267DF8] font-[600]">
                                            {' '}
                                            |{' '}
                                            {moment(date)
                                                .utcOffset(330)
                                                .format('hh:mm A')}
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                    {cardType === 'BD' && status === 'pending' && isTrainee && (
                        <p className="text-[14px] font-[500] text-[#ECB833]">
                            In Progress
                        </p>
                    )}
                    {/* <p className="text-[#5F5F5F] shrinkToTwoLines min-h-[40px]">
                        {courseDescription
                            ? courseDescription
                            : 'No Description'}
                    </p> */}
                    {/* update by me */}

                    <p
                        className="text-[#5F5F5F] shrinkToTwoLines  min-h-[40px]   "
                        dangerouslySetInnerHTML={{
                            __html: sanitizedDescription || 'No Description',
                        }}
                    ></p>
                    {cardType === 'BD' && status !== 'pending' && isTrainee && (
                        <p className="text-[#5F5F5F] font-[300] text-[14px] flex items-center">
                            Accepted bid{' '}
                            <span className="ml-2 flex items-center text-[16px] font-[500] text-[#2975FD]">
                                <img
                                    src={rs}
                                    className="mr-1"
                                    alt="rupeesSymbol"
                                />
                                {bidAmount.toLocaleString('en-IN')}
                            </span>
                        </p>
                    )}
                    {cardType === 'CTP' && status === 'pending' && (
                        <ProgressBar endDate={endDate} startDate={date} />
                    )}
                    {TraineeAndCTPAndCompleted && (
                        <StarRating
                            isClickable={false}
                            rate={averageRating?.toFixed(2)}
                            noPointer={'noPointer'}
                        />
                    )}
                    {cardType === 'DR' && (
                        <DirectRequestValues {...directRequestValues} />
                    )}
                    {cardType === 'BD' &&
                        ['TI', 'FL'].includes(userRole) &&
                        bidAmount &&
                        status !== 'rejected' && (
                            <p className="text-[#5F5F5F] flex items-center">
                                My Bid{' '}
                                <img
                                    src={rsForDR}
                                    alt="rupees"
                                    className="ml-2"
                                />{' '}
                                <span className="text-[#2975FD] text-[16px] font-[500] ml-1">
                                    {bidAmount.toLocaleString('en-IN')}
                                </span>
                            </p>
                        )}
                    {cardType === 'BD' && <hr className="w-full mx-auto" />}

                    {cardType !== 'suggest'
                        ? cardType === 'BD' && (
                              <ReadMore
                                  showEdit={showEdit}
                                  canEdit={canEditCard}
                              />
                          )
                        : isTrainee
                        ? appConfigDr && <RequestButton id={id} title={title} />
                        : appConfigBd && (
                              <PlaceBidButton id={id} title={title} />
                          )}
                </div>
            </div>
        </div>
    );
};

const dateFormat = (dateText: string) => {
    let text;
    if (moment(dateText).format('DD-MMM-YY') !== 'Invalid date') {
        text = moment(dateText).format('DD-MMM-YY');
    } else {
        text = moment(new Date()).format('DD-MMM-YY');
    }
    return text;
};

const DirectRequestValues = ({
    role,
    userRole,
    fee,
    date_of_training,
    trainees_count,
    phoneNumber,
}: any) => {
    const countConversion = () => {
        let traineeType: string = ['TI', 'ST'].includes(role)
            ? ' Student'
            : ' Employee';
        if (trainees_count > 1) {
            traineeType = traineeType + 's';
        }
        return trainees_count + traineeType;
    };

    return (
        <div className="space-y-3 font-[400] text-[12px]">
            <div className="flex w-full">
                <KeyValue
                    icon={Users}
                    value={countConversion()}
                    w={'w-[45%]'}
                />
                <KeyValue
                    icon={Cal}
                    value={dateFormat(date_of_training)}
                    w={'w-[55%] ml-2'}
                />
            </div>
            <div className="flex">
                <KeyValue
                    icon={TargetIcon}
                    value={Number(fee).toLocaleString('en-IN')}
                    type={'currency'}
                    w={'w-[45%]'}
                />
                <KeyValue
                    icon={PhoneIcon}
                    value={phoneNumber}
                    w={'w-[55%] ml-2'}
                />
            </div>
        </div>
    );
};

const KeyValue = ({ icon, value, w, type }: any) => {
    return (
        <div className={`flex items-center space-x-3 ${w}`}>
            <img src={icon} className="w-[16px] h-[20px]" alt="drIcons" />
            <p className="flex">
                {type && <img className="mr-1" src={rs} alt="rs" />}
                {value}
            </p>
        </div>
    );
};

const AcceptOrReject = ({ acceptFunction, rejectFunction, isTrainee }: any) => {
    return (
        <>
            {!isTrainee && (
                <div className="flex space-x-2 justify-end text-[12px]">
                    <button
                        className="border border-[#33BD87] text-[#33BD87] py-2 px-5 rounded-[4px]"
                        onClick={acceptFunction}
                    >
                        Accept
                    </button>
                    <button
                        className="border border-[#EA4335] text-[#EA4335] py-2 px-5 rounded-[4px]"
                        onClick={rejectFunction}
                    >
                        Reject
                    </button>
                </div>
            )}
        </>
    );
};

export const TopBids = ({
    cardtype,
    commonKeyData,
    traineeInfo,
    title,
    config,
    id,
    requote,
    status,
    isTrainee,
    topBidderDetails,
}: any) => {
    // console.log(
    //     id,
    //     requote,
    //     status,
    //     isTrainee,
    //     topBidderDetails,
    //     'topBidderDetails'
    // );
    const navigate = useNavigate();
    const bidTopText = status === 'rejected' ? 'Successful Bid' : 'Top Bid';
    if (topBidderDetails === null) {
        return (
            <div className="text-[12px] font-[300] text-[#FB5935] h-[72px] flex items-center">
                No one has bidded
            </div>
        );
    }
    const navigateTo = () => {
        navigate(`/bid/view/${title}/${id}`);
    };

    return (
        <div>
            {(topBidderDetails !== null || topBidderDetails !== undefined) &&
            !isTrainee ? (
                <div className="flex flex-col items-start w-full cursor-pointer">
                    <p className="text-[12px] font-[300] text-[#939393]">
                        {bidTopText}
                    </p>
                    <div className="w-full gap-2 flex items-center justify-between">
                        <div className="flex items-center">
                            <p className="font-[600] text-[12px]">
                                {topBidderDetails.name}
                            </p>
                            <RoleCard role={topBidderDetails.role} />
                        </div>
                        {requote && config && (
                            <button
                                className="bg-[#2975FD] text-white px-4 py-2 text-[11px] rounded-[5px]"
                                onClick={navigateTo}
                            >
                                Requote
                            </button>
                        )}
                    </div>
                    <p className="text-[#5F5F5F] flex items-center">
                        <img
                            src={grrupees}
                            alt="rs"
                            className="text-[#33BD87] h-[11px]"
                        />
                        <span className="text-[#33BD87] text-[14px] font-[500] ml-1">
                            {topBidderDetails.amount.toLocaleString('en-IN')}
                        </span>
                    </p>

                    {/* update by me */}

                    {traineeInfo && (
                        <div className="flex items-center">
                            <p className="font-[600] text-[12px]">
                                {traineeInfo?.name}
                            </p>
                            <RoleCard role={traineeInfo?.role} />
                        </div>
                    )}
                </div>
            ) : (
                config && (
                    <BidActionsForTrainees
                        status={status}
                        onClick={navigateTo}
                    />
                )
            )}
        </div>
    );
};

const BidActionsForTrainees = ({ status, onClick }: any) => {
    // console.log({status})
    const statusBasedColor: any = {
        accepted: {
            text: 'Accepted',
            bgColor: 'bg-[#D3F9EB]',
            color: 'text-[#00B89B]',
            disabled: true,
        },
        pending: {
            text: 'View',
            bgColor: 'bg-[#267DF8]',
            color: 'text-white',
            disabled: false,
        },
        rejected: {
            text: 'Accept',
            bgColor: 'bg-[#267DF8]',
            color: 'text-white',
            disabled: false,
        },
    };
    const style = `${statusBasedColor[status].bgColor} ${statusBasedColor[status].color} py-2 px-4 rounded-[4px] outline-none text-[12px]`;
    return (
        <div onClick={onClick} className="flex justify-end">
            <button
                className={style}
                disabled={statusBasedColor[status].disabled}
            >
                {statusBasedColor[status].text}
            </button>
        </div>
    );
};

export const StatusForDirectRequest = ({
    config,
    id,
    type,
    userTypeStyle,
    userTypeValue,
    user,
    title,
    textTypeOne,
    status,
    isPaymentDone,
    userId,
    courseId,
}: // useHistory
any) => {
    // const history = useHistory();
    const navigate = useNavigate();
    const statusColorForText = `${statusColor[status]} font-[300] text-[12px]`;
    const makePaymentBasedOnStatus = `bg-[#2975FD] text-[11px] text-white py-2 px-4 rounded-[4px] disabled:opacity-20`;

    const navigateTo = () => {
        navigate({
            pathname: '/checkout',
            search: createSearchParams({ id, checkoutOrigin: 'dr' }).toString(),
        });
    };

    const navigateToCoursePage = () => {
        navigate({
            pathname: `/booknow/${title}/${courseId}`,
            search: createSearchParams({ dr_id: id }).toString(),
        });
    };

    const navigateToUserProfile = () => {
        localStorage.setItem('selectedCourseUserId', JSON.stringify(userId));
        navigate('/profile/view/' + user);
    };

    return (
        <div className="space-y-3">
            {config && (
                <div className="flex justify-end">
                    <button
                        className={makePaymentBasedOnStatus}
                        disabled={
                            ['rejected', 'pending'].includes(status) ||
                            isPaymentDone
                        }
                        onClick={navigateTo}
                    >
                        {isPaymentDone ? 'Paid' : 'Make Payment'}
                    </button>
                </div>
            )}
            <div>
                <h3
                    className="font-[600] text-[26px] shrinkToTwoLines shrinkToOneLine cursor-pointer"
                    onClick={navigateToCoursePage}
                >
                    {title}
                </h3>
                <div className="w-[40px] mt-2 h-[2px] rounded-full bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
            </div>
            <div className="flex items-center mt-2 statusHighlight">
                <p className={statusColorForText}>
                    {textTypeOne}{' '}
                    <span
                        className="font-[500] cursor-pointer"
                        onClick={navigateToUserProfile}
                    >
                        {user}
                    </span>
                </p>
                <p className={userTypeStyle}>{userTypeValue}</p>
            </div>
        </div>
    );
};

const ReadMore = ({ canEdit, showEdit }: any) => {
    return (
        <div className="flex justify-end">
            {/* <div className='w-[90%]'>
                {showEdit && <img alt='editIcon' src={canEdit ? editActive : editInActive} className={`w-[15px] ${canEdit && "cursor-pointer"}`} />}
            </div> */}
            <img
                src={RightArrow}
                className="cursor-pointer arrow"
                alt="arrowLine"
            />
        </div>
    );
};

const RequestButton = ({ id, title }: any) => {
    const navigate = useNavigate();
    const navigateTo = () => {
        navigate('/course/view/' + title + '/' + id);
    };
    return (
        <div>
            <button
                className="bg-[#2975FD] text-white px-4 py-2 text-[12px] rounded-[5px]"
                onClick={navigateTo}
            >
                Book Now
            </button>
        </div>
    );
};

const PlaceBidButton = ({ id, title }: any) => {
    const navigate = useNavigate();
    const participateInBidding = async () => {
        // const confirmParticipation = window.confirm(
        //     'Are you sure to participate?'
        // );
        // if (!confirmParticipation) return false;
        // const biddingResponse = await updateRecord(
        //     { id, participate: true },
        //     'biddings'
        // );
        // if (biddingResponse.status) {
        navigate(`/bid/view/${title}/${id}`);
        //     toast.success('Participated In Bidding');
        // } else {
        //     toast.error(biddingResponse.message);
        // }
    };

    return (
        <div className="">
            <button
                className="bg-[#2975FD] text-white px-4 py-2 rounded-[5px] text-[12px]"
                onClick={participateInBidding}
            >
                Place a bid
            </button>
        </div>
    );
};

const CompleteButton = ({ id, updateTraining, isDr }: any) => {
    const completeButtonRef: any = useRef();

    const completeCourseAction = async () => {
        completeButtonRef.current.disabled = true;
        await updateTraining(id, 'completed', isDr);
        completeButtonRef.current.disabled = false;
    };
    return (
        <div className="flex justify-end">
            <button
                ref={completeButtonRef}
                className="bg-[#07BEA1] text-white px-4 py-2 rounded-[5px] text-[12px]"
                onClick={completeCourseAction}
            >
                Complete
            </button>
        </div>
    );
};

const CancelButton = ({ order_id, id, onClick }: any) => {
    return (
        <div className="flex justify-end">
            <button
                className="border border-[#EA4335] text-[#EA4335] text-[12px] px-4 py-2 rounded-[5px]"
                onClick={() => onClick(order_id, id)}
            >
                Cancel
            </button>
        </div>
    );
};

const EditIcon = ({ statusType }: any) => {
    return (
        <div>
            {statusType === 'pending' ? (
                <img src={edit_select} alt="select" />
            ) : (
                <img src={edit_unselect} alt="unselect" />
            )}
        </div>
    );
};
