import { useState } from 'react';
import { toast } from 'react-hot-toast';
import closeBtn from '../../assets/Images/closeBtn.svg';
import { getTomorrowDate } from '../../Services/commonFunctions';
import { InputTag } from './InputTag';
import { BatchTimingLine } from './TagsForSelectTime';

interface props {
    dates: any;
    setFieldValue: any;
    isCustomizeCourse: boolean;
    isEdit: boolean;
    batch?: string;
    error?: any;
    roleSelection?: any;
}

export const DateAndBatchTimings = ({
    dates,
    setFieldValue,
    isCustomizeCourse,
    isEdit,
    batch,
    error,
    roleSelection,
}: props) => {
    const [chooseDate, setChooseDate] = useState('');
    const [datesArray, setDatesArray] = useState([]);

    // const removeDate = (dateString: string) => {
    //     let dateObject = { ...dates };
    //     delete dateObject[dateString];
    //     setFieldValue('date_and_timings', dateObject);
    // };
    // const [selectedDate, setSelectedDate] = useState<string>('');

    // const onSelectDate = (dateString: string) => {
    //     setSelectedDate(dateString);
    //     // onSelect()
    // };

    // const addDates = () => {
    //     if ([...Object.keys(dates)].includes(chooseDate)) {
    //         return toast.error('Training Date already present');
    //     }
    //     // !selectedDate.length && setSelectedDate(chooseDate);
    //     setFieldValue('date_and_timings', { ...dates, [chooseDate]: [] });
    // };

    // const convertDatesTo
    return (
        <div>
            <div className="w-1/2 flex justify-between items-center">
                <InputTag
                    name="date_of_training"
                    type={'date'}
                    label={'Start Date'}
                    min={getTomorrowDate()}
                    className={'h-[55px] mt-6 rounded-[8px]'}
                    Tagwidth={'w-1/2'}
                    value={chooseDate}
                    onChange={(e: any) => {
                        setChooseDate(e.target.value);
                    }}
                    maxlength="4"
                    pattern="[1-9][0-9]{3}"
                    max={'9999-12-31'}
                />
                {/* <button
                    type="button"
                    className="bg-white w-[225px] h-[55px] rounded-[8px] text-[#085BEB] text-[15px] font-[600] border border-[#085BEB]"
                    onClick={addDates}
                >
                    ADD
                </button> */}
            </div>
            {/* <div className="mb-8">
                <label className="text-[15px] font-[500]">Training Dates</label>
                <div className="bg-white w-full h-[55px] px-4 mt-4 rounded-[8px] flex space-x-5 items-center overflow-x-auto overflow-y-hidden">
                    {[...Object.keys(dates)].map(
                        (bt: string, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={`py-2 px-4 flex items-center space-x-3 rounded-[17px] bg-[#F8F8F8] ${
                                        selectedDate === bt &&
                                        'bg-[#065FEF] text-white'
                                    }`}
                                >
                                    <p onClick={() => onSelectDate(bt)}>{bt}</p>
                                    <img
                                        src={closeBtn}
                                        alt="closeBtn"
                                        className="h-[12px] cursor-pointer"
                                        onClick={() => removeDate(bt)}
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            </div> */}
            <BatchTimingLine
                batch_timings={dates}
                setFieldValue={setFieldValue}
                isCustomizeCourse={isCustomizeCourse}
                chooseDate={chooseDate}
                isEdit={isEdit}
                batch={batch}
                error={error}
                roleSelection={roleSelection}
            />
        </div>
    );
};
