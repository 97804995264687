import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../..';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { postRecord } from '../../Services/backend/apiCalls';
import { BookNow } from '../../Services/Formik/Formik';
import { showBookNowModal } from '../../Redux/Reducers/DRReducers';
import { getTomorrowDate } from '../../Services/commonFunctions';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
import { ButtonCompWithActionController } from '../Elements/Button/Button';
import { dateFormat } from '../ViewCourse/DateFormat';
import moment from 'moment';

interface BookNowProps {
    courseId: number;
    batchData: any;
}

export const BooknowPopup = ({ courseId, batchData }: BookNowProps) => {
    // const batches = batchData?.filter(
    //     (bt: any) => dateFormat(bt?.batchDate) >= dateFormat(new Date())
    // );
    const batches = batchData?.filter((bt: any) => {
        const batchDate = moment(bt?.batchDate).format('YYYY-MM-DD'); // Use the same format as in the array
        const currentDate = moment().format('YYYY-MM-DD');
        return batchDate >= currentDate;
    });
    // console.log(batches, "batches", dateFormat(new Date()), "new Date()", batchData, "batchData")
    // const [selectedBatchTime, setSelectedBatchTime] = useState('');

    const { token, attendedCourses, directRequestedIds }: any = useAppSelector(
        (state) => state.signin.signinValue
    );
    const navigate = useNavigate();
    const sendButtonRef = useRef<any>(null);
    const dispatch = useAppDispatch();

    const courseBookedTimings = useAppSelector((state: any) => {
        return state.compare?.compareCoursesData?.bookedBatchTime?.[0];
    });

    // const getNonBookedCourseData = batches && batches?.filter((batch: any) => {
    //     return !courseBookedTimings[0].some(
    //         (item: any) => batch.batchTime === item.batch_timing
    //     );
    // });

    const getNonBookedCourseData = batches?.filter((batch: any) => {
        return !courseBookedTimings.some(
            (item: any) =>
                `${dateFormat(batch.batchDate)}(${batch.batchTime})` ===
                `${dateFormat(item.batch_date)}(${item.batch_timing})`
        );
    });
    // console.log(
    //     courseBookedTimings,
    //     'courseBookedTimings',
    //     getNonBookedCourseData,
    //     'getNonBookedCourseData'
    // );

    // const res = batches && batches?.reduce((acc: any, cru: any) => {
    //     const isCheck = courseBookedTimings[0].some(
    //         (couBookTime: any) =>
    //         (couBookTime.batch_timing === cru.batchTime &&
    //             couBookTime.isPaymentDone != false &&
    //             couBookTime.isCancelled != false) ||
    //           (couBookTime.batch_timing === cru.batchTime &&
    //             couBookTime.isCancelled === true)
    //     );

    //     if (isCheck) {
    //         acc.push(cru);
    //     }
    //     return acc;
    // }, []);

    const getNonBookedCourse = [...getNonBookedCourseData];
    // const getNonBookedCourse = [...getNonBookedCourseData, ...res];

    const initialValues = {
        batch_timing: '',
        date_of_training: '',
    };

    const sendDirectRequest = async () => {
        // let selectValues: any = {};
        // if (selectedBatchTime) {
        //     selectValues = batches && batches?.find((ele: any) => {
        //         if (ele.batchTime === selectedBatchTime) {
        //             return {
        //                 batch_timing: ele.batchDate,
        //                 date_of_training: ele.batchTime,
        //             };
        //         }
        //     });
        // } else {
        //     selectValues = getNonBookedCourse.find((ele: any) => {
        //         return {
        //             batch_timing: ele.batchDate,
        //             date_of_training: ele.batchTime,
        //         };
        //     });
        // }

        const { batch_timing, date_of_training } = values;

        const body = {
            course_id: Number(courseId),
            batch_timing: batch_timing,
            date_of_training: date_of_training,
        };
        // console.log(body, "body")
        const response = await postRecord(body, 'directrequests');
        // console.log(response, "response")
        if (response.status) {
            toast.success('Direct sent successfully');
            navigate({
                pathname: '/checkout',
                search: createSearchParams({
                    id: response.data.id,
                    checkoutOrigin: 'dr',
                }).toString(),
            });
        } else {
            toast.error(response.message);
        }
        dispatch(showBookNowModal(false));
        dispatch(
            setSignValue({
                directRequestedIds: [...directRequestedIds, courseId],
            })
        );
    };

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema: BookNow,
        onSubmit: sendDirectRequest,
    });
    const batchDates = useMemo(() => {
        const bt = [
            ...new Set(
                getNonBookedCourse &&
                    getNonBookedCourse?.map((batch: any) => batch.batchDate)
            ),
        ];
        setFieldValue('date_of_training', bt[0]);
        return bt;
    }, []);

    // console.log(
    //     batchDates,
    //     'batchDates',
    //     batches,
    //     'batches',
    //     getNonBookedCourse,
    //     'getNonBookedCourse'
    // );

    const batchTimings = useMemo(() => {
        if (!values.date_of_training?.length) {
            return (
                getNonBookedCourse &&
                getNonBookedCourse
                    ?.filter((batch: any) => batch.batchDate === batchDates[0])
                    .map((batch: any) => batch.batchTime)
            );
        }
        return (
            getNonBookedCourse &&
            getNonBookedCourse
                ?.filter(
                    (batch: any) => batch.batchDate === values.date_of_training
                )
                .map((batch: any) => batch.batchTime)
        );
    }, [values.date_of_training]);

    // console.log(batchTimings, "batchTimings")

    useEffect(() => {
        if (batchTimings && batchTimings.length) {
            // console.log(batchTimings[0], "batchTimings[0]")
            setFieldValue('batch_timing', batchTimings[0]);
        }
    }, [JSON.stringify(batchTimings)]);

    const closeModal = () => {
        dispatch(showBookNowModal(false));
    };

    // const batchTimehandleChange = (e: any) => {
    //     setSelectedBatchTime(e.target.value);
    // };

    const checkDate = (e: any) => {
        setFieldValue(
            'date_of_training',
            moment(e.target.value).format('YYYY-MM-DD')
        );
    };

    return (
        <div className="h-[100%] w-full flex items-center bg-black bg-opacity-[0.64] fixed overflow-auto top-0 py-10 z-[3]">
            <form className="w-1/4 mx-auto bg-[#F6F7F8] rounded-md p-6 space-y-5">
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="bg-red-400 text-white rounded-sm px-4 py-2"
                        onClick={closeModal}
                    >
                        Close
                    </button>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="dateOfTraining" className="font-bold">
                        Date Of Training
                    </label>
                    <select
                        title="batchDates"
                        name="date_of_training"
                        id="batchDate"
                        className="mt-2 py-3 bg-white outline-none w-full rounded-md px-2"
                        defaultValue={values.date_of_training}
                        onBlur={handleBlur}
                        onChange={checkDate}
                    >
                        {batchDates.map((batch: any, index: number) => {
                            return (
                                <option key={index}>
                                    {moment(batch).format('DD-MMM-YYYY')}
                                </option>
                            );
                        })}
                    </select>
                    {errors.date_of_training && (
                        <p className="text-red-600 text-[13px] mt-1">
                            {errors.date_of_training}
                        </p>
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="batchTiming" className="font-bold">
                        Batch Timings
                    </label>
                    {/* {batchTimings.length > 0 ? ( */}
                    <select
                        title="batchTimings"
                        name="batch_timing"
                        id="batchTiming"
                        className="mt-2 py-3 bg-white outline-none w-full rounded-md px-2"
                        defaultValue={values.batch_timing}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        {batchTimings &&
                            batchTimings.map(
                                (batchTime: any, index: number) => {
                                    return (
                                        <option key={index}>{batchTime}</option>
                                    );
                                }
                            )}
                    </select>
                    {/* // ) : (
                    //     <h2>No Batch timing available all are booked</h2>
                    // )} */}
                    {/* {getNonBookedCourse.length > 0 ? (
                        <select
                            title="batchTimings"
                            name="batch_timing"
                            id="batchTiming"
                            className="mt-2 py-3 bg-white outline-none w-full rounded-md px-2"
                            defaultValue={values.batch_timing}
                            onBlur={handleBlur}
                            onChange={batchTimehandleChange}
                        >
                            {getNonBookedCourse &&
                                getNonBookedCourse.map(
                                    (batchTime: any, index: number) => {
                                        return (
                                            <option key={index}>
                                                {batchTime.batchTime}
                                            </option>
                                        );
                                    }
                                )}
                        </select>
                    ) : (
                        <h2>No Batch timing available all are booked</h2>
                    )} */}

                    {errors.batch_timing && (
                        <p className="text-red-600 text-[13px] mt-1">
                            {errors.batch_timing}
                        </p>
                    )}
                </div>

                <ButtonCompWithActionController
                    type="button"
                    disabled={Boolean(Object.keys(errors).length)}
                    className="text-white w-full rounded-md bg-blue-600 py-2"
                    buttonText={'Book'}
                    action={handleSubmit}
                    Ref={sendButtonRef}
                />
            </form>
        </div>
    );
};
