import { useState, useRef } from 'react';
import { Input } from '../Elements/Input/Input';
import pdfIcon from '../../assets/Images/pdfIcon.svg';
import closeBtn from '../../assets/Images/closeBtn.svg';
import toast from 'react-hot-toast';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import deleteIcon from '../../assets/Images/deleteIcon.svg';
import { ResumeFile } from '../SignupUserDetails/ResumeFile';

export const UserInfo = ({
    resumeStatus,
    resumefile,
    deleteFile,
    role,
    formik,
    uploadResume,
    cardTitle,
    isViewable,
}: any) => {
    const [input, setInput] = useState('');
    const inputRef: any = useRef(null);
    let skillSet = formik.values.skills;
    // console.log(skillSet.join(','));

    const setTags = (tag: string) => {
        if (!tag.length) {
            return toast.error(
                camelCaseConvertForErrorMessage('Please Enter Tags')
            );
        }
        if (skillSet.includes(tag.toLowerCase())) {
            return toast.error(
                camelCaseConvertForErrorMessage('Tag Already Entered')
            );
        }
        skillSet = skillSet.join(',');
        // formik.setFieldValue('skills', skillsSet);
        // formik.setFieldValue('skills', [...formik.values.skills, tag.toLowerCase()]);
    };

    const removeTags = (skill: any) => {
        const Tags = skillSet.filter((idx: any) => idx !== skill);
        formik.setFieldValue('skills', Tags);
    };

    return (
        <div>
            <h1 className="text-[#2580F5] text-[25px] font-[500] px-6">
                {cardTitle} Information
            </h1>
            <div className="bg-white p-12 rounded-[30px] mt-4 space-y-4">
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                    {role === 'CO' && (
                        <>
                            <Input
                                name="name"
                                disabled={isViewable}
                                label="Organization Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.name}
                                isTouched={formik.touched.name}
                                impo="impo"
                            />
                            <Input
                                name="company_url"
                                disabled={isViewable}
                                label="Company URL"
                                value={formik.values.company_url}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.company_url}
                                isTouched={formik.touched.company_url}
                                impo="impo"
                            />
                        </>
                    )}
                    {(role === 'TI' || role === 'FL' || role === 'ST') && (
                        <Input
                            name="name"
                            disabled={isViewable}
                            label={role === 'TI' ? 'Institute Name' : 'Name'}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.name}
                            isTouched={formik.touched.name}
                            impo="impo"
                        />
                    )}
                    {(role === 'TI' || role === 'FL') && (
                        <Input
                            name="industry"
                            // updated by me

                            disabled={true}
                            label="Industry Type"
                            value={formik.values.industry}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.industry}
                            isTouched={formik.touched.industry}
                            impo="impo"
                        />
                    )}
                    {role === 'FL' && (
                        <Input
                            name="linkedin_url"
                            disabled={isViewable}
                            label="LinkedIn URL"
                            value={formik.values.linkedin_url}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.linkedin_url}
                            isTouched={formik.touched.linkedin_url}
                            impo="impo"
                        />
                    )}
                    {role === 'TI' && (
                        <>
                            <Input
                                name="url"
                                disabled={isViewable}
                                label="Institute URL"
                                value={formik.values.url}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.url}
                                isTouched={formik.touched.url}
                                impo="impo"
                            />
                            <Input
                                name="gst_number"
                                disabled={isViewable}
                                label="GSTIN Number"
                                value={formik.values.gst_number}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // error={formik.errors.gst_number}
                                // isTouched={formik.touched.gst_number}
                                placeholder="Enter your institute GST number"
                                impo="impo"
                            />
                        </>
                    )}
                </div>
                {role === 'FL' && (
                    //(formik.values.resume_url) ?
                    <>
                        <Input
                            name="skills"
                            label="Skills"
                            disabled={isViewable}
                            value={formik.values.skills}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.skills}
                            isTouched={formik.touched.skills}
                            impo="impo"
                        />
                        <ResumeFile
                            resumefile={resumefile}
                            resumeStatus={resumeStatus}
                            formik={formik}
                            deleteFile={deleteFile}
                            from={'profile'}
                        />
                        {/* <Input
                            id="resumeUpload"
                            disabled
                            hidden
                            name="resume_url"
                            label="Upload Resume"
                            accept={'application/pdf'}
                            type={'file'}
                            onChange={uploadResume}
                            onBlur={formik.handleBlur}
                            error={formik.errors.resume_url}
                            isTouched={formik.touched.resume_url}
                            impo={'impo'}
                        /> */}
                        {/* <div className="flex relative">
                            <a
                                href={formik.values.resume_url}
                                rel="noreferrer"
                                target="_blank"
                                className="my-1 border p-2 flex justify-between rounded-lg w-full"
                            >
                                <div className="flex space-x-3 items-center ml-2">
                                    <img
                                        src={pdfIcon}
                                        alt="pdfIcon"
                                        className="w-[20px]"
                                    />
                                    <p className="text-[16px] font-[500] text-black">
                                        {formik.values.name}_Resume.pdf
                                    </p>
                                </div>
                            </a>
                            <img
                                alt="deleteIcon"
                                src={deleteIcon}
                                onClick={deleteFile}
                                className="absolute right-4 top-2 cursor-pointer"
                            />
                        </div> */}
                    </>
                )}
                {/* </>:
                <div className='mt-2 h-[160px] bg-[#F6F8FC] border-dashed border-[2px] rounded-[8px] border-[#2580F5] flex flex-col justify-center items-center gap-2'>
                  <div className='bg-[#E7EEFB] rounded-full p-4'>
                    <img src={pdfIcon} alt="pdfIcon" className='w-[20px]' />
                  </div>
                  <input className='w-[20px]' id="resumeUpload" hidden name="resume_url" accept={"image/*"} type={"file"} onChange={uploadResume}/>
                    <label className='cursor-pointer' htmlFor='resumeUpload'>
                    <div className='flex items-center'>
                      <h1 className='text-[14px] font-[500]'>Drag and Drop files, or</h1>
                      <p className='ml-1 text-[#2580F5] text-[14px] font-[600]'>{resumeStatus}</p>                             
                    </div>
                    </label>
                </div>
            }
            </div>} */}
            </div>
        </div>
    );
};
