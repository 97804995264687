import { useState } from 'react';
import { Input } from '../../Elements/Input/Input';
import curveLine from '../../../assets/Images/curveLine.png';
import Login_Logo from '../../../assets/Images/Login_Logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ChangePasswordValidationSchema } from '../../../Services/Formik/Formik';
import toast from 'react-hot-toast';
import { updateRecord } from '../../../Services/backend/apiCalls';
import { passwordDecryptSecretKey } from '../../../config';
import * as CryptoJS from 'crypto-js';
import { PulseLoader } from 'react-spinners';

export const ConfirmPassword = () => {
    const [isFocused, setIsFocused] = useState(false); // State to manage focus on password field

    const navigate = useNavigate();
    const { search } = useLocation();
    const [loading, setLoading] = useState(false);
    const getSplitData = search.split('?');
    const getEmail = getSplitData[1].split('=');
    const expiresIn = getSplitData[2].split('=');
    const getAuth = getSplitData[3].split('=');

    const handleSubmit = async () => {
        setLoading(true);
        const currentTime = new Date().getTime();
        if (Number(expiresIn[1]) > currentTime) {
            const data = CryptoJS.AES.encrypt(
                JSON.stringify(formik.values.new_password),
                passwordDecryptSecretKey
            ).toString();
            const body = {
                email: getEmail[1],
                new_password: data,
                validateString: getAuth[1],
            };
            const updatePassword = await updateRecord(body, 'updatepassword');
            if (updatePassword.status) {
                navigate('/login');
                toast.success(updatePassword.message);
            } else toast.error(updatePassword.message);
        } else {
            navigate('/login');
            toast.error('Link Expired');
        }
        setLoading(false);
    };

    const formik = useFormik({
        initialValues: {
            new_password: '',
            // confirm_password: '',
        },

        validationSchema: ChangePasswordValidationSchema,
        onSubmit: () => {
            handleSubmit();
        },
    });

    return (
        <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12">
            <img
                src={curveLine}
                alt="CurveLine"
                className="absolute top-0 right-0"
            />
            <form
                className="flex flex-col justify-center items-start w-[222px] lg:w-[292px] mx-auto space-y-4 h-3/4"
                onSubmit={formik.handleSubmit}
            >
                <div>
                    <img
                        src={Login_Logo}
                        alt="Login_Logo"
                        className="w-[190px]"
                    />
                </div>
                <div className="w-full">
                    <Input
                        autoFocus
                        labeldesign={'mb-2'}
                        placeholder="Enter Password"
                        design={'w-full pt-10'}
                        name="new_password"
                        label="Password"
                        type={'password'}
                        value={formik.values.new_password}
                        onChange={formik.handleChange}
                        //   onBlur={formik.handleBlur}
                        //   error={formik.errors.new_password}
                        //   isTouched={formik.touched.new_password}
                        onBlur={(e: any) => {
                            formik.handleBlur(e);
                            setIsFocused(false);
                        }}
                        onFocus={() => setIsFocused(true)}
                        error={formik.errors.new_password}
                        isTouched={formik.touched.new_password}
                        impo="impo"
                    />
                    {isFocused && (
                        <PasswordValidation
                            password={formik.values.new_password}
                        />
                    )}

                    {/* <Input
                      labeldesign={'mb-2'}
                      //   disabled={isSendClicked}
                      placeholder="Enter Confirm Password"
                      design={'w-full pt-5'}
                      name="confirm_password"
                      label="Confirm Password"
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.confirm_password}
                      isTouched={formik.touched.confirm_password}
                  /> */}
                </div>
                <div className="w-full pt-6">
                    {loading ? (
                        <div className="flex justify-center bg-gradient-to-r from-[#0074FC] to-[#00E0B8] w-full p-3 text-[12px] font-[400] text-white rounded-md">
                            <PulseLoader color="#ffffff" size={8} />
                        </div>
                    ) : (
                        <button
                            className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] w-full p-3 text-[12px] font-[400] text-white rounded-md"
                            type="submit"
                        >
                            Save
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

const PasswordValidation: React.FC<{ password: string }> = ({ password }) => {
    const requirements = [
        { text: 'Minimum of 8 characters', regex: /.{8,}/ },
        { text: 'Contains an uppercase letter', regex: /[A-Z]/ },
        { text: 'Contains a number (0-9)', regex: /\d/ },
        {
            text: 'Contains special characters (!@#$%^&*)',
            regex: /[!@#$%^&*(),.?":{}|<>]/,
        },
    ];

    const checkRequirement = (regex: RegExp) => regex.test(password);

    return (
        <div className="absolute top-80 right-[300px] mt-10  w-200 bg-white border border-gray-300 rounded-lg shadow-lg p-4 z-10">
            <h4 className="text-sm font-bold mb-2">Password Requirements</h4>
            <ul className="list-disc list-inside">
                {requirements.map((req, index) => (
                    <li
                        key={index}
                        className={
                            checkRequirement(req.regex)
                                ? 'text-green-600'
                                : 'text-red-600'
                        }
                    >
                        {req.text}
                    </li>
                ))}
            </ul>
        </div>
    );
};
