import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import currencyIcon from "../../assets/Images/currencyIcon.svg";
import blueCurrencyIcon from "../../assets/Images/blueCurrencyIcon.svg";
import redCurrencyIcon from "../../assets/Images/redCurrencyIcon.svg";

import { toast } from 'react-hot-toast';
import {readRecord } from '../../Services/backend/apiCalls';
import { useAppSelector } from '../..';
import { daysConversionAndSort } from '../../Services/commonFunctions';
import { PulseLoader } from 'react-spinners';
import { dateFormat } from '../ViewCourse/DateFormat';
import { RoleCard } from './Role';
import { createOrder } from '../../Services/razorPay';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { CommonRequestCard } from '../CommonRequestCard/CommonRequestCard';

export const Checkout = () => {
  const sectionName = "checkout";
  // const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const checkoutOrigin = searchParams.get("checkoutOrigin") ?? ""
  // const { state }:any = location;
  const [showCoupons, setShowCoupons] = useState(false);
  const [couponValue, setCouponValue] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponText, setCouponText] = useState("");
  const [isButtonActive, setButtonActive] = useState(false);
  const { token } = useAppSelector(({ signin }: any) => signin["signinValue"]);
  const convertCurrencyWithComma = (amount: any) => {
    return Number(amount).toLocaleString('en-IN');
  }
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  const [checkoutDetails, setCheckoutDetails] = useState<any>({
    courseImage:"",
    courseTitle: "",
    trainerName: "",
    trainerRole: "TI",
    duration: "",
    isDiscountAvailable: false,
    discount:0,
    days: "",
    startDate: "",
    batchTiming: "",
    internetCharge: 0,
    gst: 0,
    chargeType:"",
    courseFee:0
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      // token, state.id, state.checkoutOrigin
      const { status, data, message } = await readRecord({id,checkoutOrigin},"checkout");
      // console.log(data,dateFormat({date:data.date_of_training}));
      if (status) {
        setCheckoutDetails({
          ...checkoutDetails,
          courseImage: data.course?.image,
          courseTitle: data.course?.title,
          trainerName: data.Trainer?.name,
          trainerRole: data.Trainer?.role,
          duration: data.duration,
          days: daysConversionAndSort(data.mode_of_teaching?.session),
          isDiscountAvailable: data.course?.discount_available,
          discount:data.course?.discount_percentage,
          startDate: dateFormat(data.date_of_training),
          batchTiming: data.batch_timing,
          internetCharge: data.charges?.internetCharge,
          chargeType:data.charges?.charge_type,
          gst: data.charges?.gst,
          courseFee: data.fee
        });
        setCoupons(data.coupons);
      }
      else {
        toast.error(message);
      }
      setLoading(false);
    })()
  }, []);

  
  let finalAmount = Number(checkoutDetails["courseFee"]);
  let discountAmount=0;
  if (checkoutDetails["isDiscountAvailable"]) {
    discountAmount = finalAmount * (Number(checkoutDetails["discount"]) / 100);
  }
  finalAmount -= discountAmount;

  const internetChargeOnCourseFee =
      checkoutDetails['chargeType'] === 'percent'
          ? Number(checkoutDetails['courseFee']) *
            (Number(checkoutDetails['internetCharge']) / 100)
          : Number(checkoutDetails['internetCharge']);
  const gstOnCourse = Number(Number(internetChargeOnCourseFee * (Number(checkoutDetails["gst"]) / 100)).toFixed(2));
  const totalTax = Number((internetChargeOnCourseFee + gstOnCourse).toFixed(2));
  finalAmount += totalTax
  finalAmount -= couponValue;
  finalAmount = Number(finalAmount.toFixed(2));


  const applyCouponDiscount = (coupon: any) => {
    setCouponCode(coupon.code);
    if (coupon.discount) {
      let discountedAmount = (Number(checkoutDetails["courseFee"]) * (coupon.discount / 100));
      setCouponText(coupon.discount + "%");
      setCouponValue(discountedAmount);
      return
    }
    setCouponValue(coupon.amount);
    setCouponText("");
  }

  const courseDetails = [
    {
      value: checkoutDetails["duration"],
      name:"Duration"
    },
    {
      value: checkoutDetails["days"],
      name:"Day"
    },
    {
      value: checkoutDetails["startDate"],
      name:"Start Date"
    },
    {
      value: checkoutDetails["batchTiming"],
      name:"Batch Timing"
    }
  ]

  const paymentSuccessAction = () => {
    toast.success("Added to training");
      navigate("/dashboard");
  }
  
  const payAction = async () => {
    setButtonActive(true);
    const CourseFeeInNumber = Number(checkoutDetails['courseFee']);
    const paymentResponse = await createOrder(
        Number(id),
        checkoutOrigin.toString(),
        finalAmount,
        couponCode,
        checkoutDetails['isDiscountAvailable']
            ? checkoutDetails['discount']
            : null,
        CourseFeeInNumber,
        totalTax,
        paymentSuccessAction,
        navigate
    );
    setButtonActive(false);

  }

  if (loading) {
    return (
      <div className='h-[400px] flex justify-center items-center'>
        <PulseLoader color="#0074FC"/>
      </div>
    )
  }

  const TaxesAndCharges = <div className='absolute child hidden z-[2] p-5 bg-white bottom-9 w-[120%] right-[-10%] shadow-md space-y-6'>
      <h4 className='text-[#030101] font-[500] text-[18px]'>Taxes & Charges</h4>
      <div className='space-y-3'>
        <JustifyBetweenComponent leftValue={"Internet handling charges"} rightValue={internetChargeOnCourseFee} />
        <JustifyBetweenComponent leftValue={"GST"} rightValue={gstOnCourse} />
    </div>
    <p className='font-[400] text-[#686B78]'>Trisekt plays no role in taxes & charges levied by the Govt.</p>
    <div className='drop-shadow-lg absolute left-[18%] bottom-[-16%] 2xl:bottom-[-17%]'>
      <div className='w-[40px] h-[40px] arrowDown bg-white'></div>
    </div>
  </div>

  return (
      <section className=" bg-[#F6F7F8] ">
          <div className="w-11/12 mx-auto py-6">
              <h1 className="mt-5 font-[500] text-[25px]">Check Out</h1>
              <div className="flex items-start flex-wrap xl:flex-nowrap xl:space-x-4 mt-4">
                  {/* Left card */}
                  <div className="w-full xl:w-8/12 p-6 bg-white">
                      <CommonRequestCard
                          courseImg={checkoutDetails['courseImage']}
                          title={checkoutDetails['courseTitle']}
                          name={checkoutDetails['trainerName']}
                          role={checkoutDetails['trainerRole']}
                          courseDetails={courseDetails}
                      />
                      {coupons.length > 0 && (
                          <div className="flex space-x-3 items-center mt-[100px]">
                              <input
                                  type="checkbox"
                                  id={sectionName + 'applyCouponCheckBox'}
                                  checked={showCoupons}
                                  onChange={() => {
                                      setShowCoupons(!showCoupons);
                                      setCouponValue(0);
                                  }}
                                  title="apply coupon"
                                  className="border-[#D4D5D9] w-[23px] h-[23px] cursor-pointer"
                              />
                              <label
                                  htmlFor={sectionName + 'applyCouponCheckBox'}
                                  className="text-[16px] font-[500] cursor-pointer select-none"
                              >
                                  Apply Coupon
                              </label>
                          </div>
                      )}

                      {showCoupons && (
                          <CouponsList
                              setCouponValue={applyCouponDiscount}
                              coupons={coupons}
                          />
                      )}
                  </div>
                  {/* Right card */}
                  <div className="w-full mt-6 xl:mt-0 xl:w-4/12 p-6  bg-white space-y-6 sticky top-2">
                      <p className="text-[#5F5F5F] font-[500] text-[16px]">
                          Order Summary
                      </p>
                      <JustifyBetweenComponent
                          leftValue={checkoutDetails['courseTitle']}
                          rightValue={convertCurrencyWithComma(
                              checkoutDetails['courseFee']
                          )}
                      />
                      {checkoutDetails['isDiscountAvailable'] && (
                          <JustifyBetweenComponent
                              leftValue={'Discount'}
                              rightValue={convertCurrencyWithComma(
                                  discountAmount
                              )}
                              operator={'-'}
                              isCoupon={true}
                              couponText={checkoutDetails['discount'] + '%'}
                          />
                      )}
                      {couponValue > 0 && (
                          <JustifyBetweenComponent
                              leftValue={'Coupon Applied'}
                              rightValue={convertCurrencyWithComma(couponValue)}
                              operator={'-'}
                              isCoupon={true}
                              couponText={couponText}
                          />
                      )}
                      <div className="flex items-center justify-between relative parent">
                          {TaxesAndCharges}
                          <p className="font-[500] text-[16px] text-[#333333] border-b border-dashed border-blue-500">
                              Taxes & Charges
                          </p>
                          <p className="flex items-center font-[600] text-[15px]">
                              +{' '}
                              <img
                                  src={currencyIcon}
                                  alt="currencyIcon"
                                  className="mx-1"
                              />
                              {convertCurrencyWithComma(totalTax)}
                          </p>
                      </div>
                      <div>
                          <div className="flex justify-between items-center border-t border-b py-3 mt-10">
                              <p className="font-[600] text-[16px]">Total</p>
                              <p className="text-blue-500 flex items-center font-[600]">
                                  <img
                                      src={blueCurrencyIcon}
                                      alt="currencyIcon"
                                      className="mx-1 fill-blue-500"
                                  />
                                  {convertCurrencyWithComma(finalAmount)}
                              </p>
                          </div>
                      </div>
                      <div className="flex justify-center">
                          <button
                              className="bg-[#2975FD] text-white px-10 py-2 rounded-[6px]"
                              disabled={isButtonActive}
                              onClick={payAction}
                          >
                              Pay
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
}

export const JustifyBetweenComponent = ({leftValue,rightValue,operator,isCoupon,couponText}: any) => {
  return (
    <div>
      <div className={`flex items-center justify-between`}>
              <p className={`font-[500] text-[16px] text-[#333333]`}>{leftValue}</p>
        <p className={`flex items-center font-[600] text-[15px] ${isCoupon && "text-[#FB5935]"}`}>
          {operator}
          <img src={isCoupon ? redCurrencyIcon : currencyIcon} alt="currencyIcon" className='mx-1' />{rightValue.toLocaleString("en-IN")}
          {isCoupon && couponText.length>0 &&<span>({couponText})</span>}
        </p>
            </div>
    </div>
  )
}

export const CouponsList = ({setCouponValue,coupons}:any) => {
  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [seeMoreClicked, setSeeMoreClicked] = useState(false);
  const couponsList=seeMoreClicked ? coupons : coupons.slice(0, 2);

  const applyCoupon = async() => {
    const couponCodeDetails = coupons.filter((coupon: any) => {
      if (coupon.code === couponCode) return coupon;
    });
    if (couponCodeDetails.length) {
      // api call to validate coupon
      setCouponValue(couponCodeDetails[0]);
      setIsCouponApplied(true);
      return toast.success(couponCode + " coupon applied");
    }
    return toast.error(camelCaseConvertForErrorMessage('Invalid Coupon Code'));
  }

  const makeBold = (item:string, keyword:string) => {
      var re = new RegExp(keyword, 'g')
      return (
          item.replace(re, '<b>'+keyword+ '</b>')
      )
  }
 

  return (
    <div className='mt-16 pb-6'>
      <div className='flex items-center space-x-5'>
        <input type="text" className='border border-[#D4D5D9] outline-none p-3 w-6/12' disabled={isCouponApplied} placeholder='Enter the coupon code' title="couponcodeinput" id='inputcouponcode' value={couponCode} onChange={(e: any) => setCouponCode(e.target.value)}/>
        <button id='applycouponcode' onClick={applyCoupon} className={`${isCouponApplied?"bg-[#E7EFFF] text-[#2975FD]":"bg-[#2975FD] text-white"} py-3 px-6 rounded-[2px] text-white"`} disabled={!couponCode.length || isCouponApplied}>{!isCouponApplied?"Apply":"Applied"}</button>
      </div>
      {!isCouponApplied && <div>
        <div className='flex flex-wrap mt-10'>
        {couponsList.map((coupon: any, index: number) => {
            return (
            <div key={index} className={`w-1/2 ${!(index%2)&&"lg:border-r border-[#E3E3E3] pr-5"} ${index%2 && "pl-4"} space-y-4 mb-10`}>
                <h3 className='font-[500] text-[18px]' dangerouslySetInnerHTML={{ __html:coupon.title}} />
              <p className='font-[400] text-[14px] text-[#686B78]' dangerouslySetInnerHTML={{__html:makeBold(coupon.description,coupon.code)}}/>
              <button className={`${couponCode===coupon.code?"bg-[#D3F9EB] text-[#00B89B]":"bg-[#00B89B] text-white"} px-4 py-2 rounded-[2px]`} disabled={couponCode===coupon.code} onClick={()=>setCouponCode(coupon.code)}>{couponCode===coupon.code?"Added":"Add Coupon"}</button>
            </div>
          )
        })}
      </div>
      <p className='font-[600] text-[#2975FD] cursor-pointer mt-8 select-none' onClick={() => setSeeMoreClicked(!seeMoreClicked)}>{seeMoreClicked?"SEE LESS -":"SEE MORE +"}</p>
      </div>}
    </div>
  )
}
