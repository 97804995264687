import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { readRecord, updateRecord } from '../../Services/backend/apiCalls';
import { useAppSelector } from '../..';
import profileViewCall from '../../assets/Images/profileViewCall.svg';
import profileViewMail from '../../assets/Images/profileViewMail.svg';
import profileViewResume from '../../assets/Images/profileViewResume.svg';
import profileViewCA from '../../assets/Images/profileViewCA.svg';
import profileViewCT from '../../assets/Images/profileViewCT.svg';
import profileViewHS from '../../assets/Images/profileViewHS.svg';
import profileViewIC from '../../assets/Images/profileViewIC.svg';
import profileViewOT from '../../assets/Images/profileViewOT.svg';
import profileViewPA from '../../assets/Images/profileViewPA.svg';

export const ProfileViewPage = () => {
    // const { uid } = useParams();
    // console.log(uid, "id")
    // const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState({} as any);
    const { role } = useAppSelector(({ signin }: any) => signin['signinValue']);
    const checkRole = ['TI', 'FL'].includes(role);

    const uid = JSON.parse(localStorage.getItem('selectedCourseUserId') || '');
    useEffect(() => {
        (async () => {
            setLoading(true);
            const ProfileView = await readRecord({ uid }, 'profiles');
            if (ProfileView.status) {
                // navigate(`/profile/view/${uid}/${ProfileView.data.name}`)
                // console.log(ProfileView.data, "VIEW")
                setProfileData(ProfileView.data);
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>
            {loading ? (
                <div className="flex w-full min-h-[450px] items-center justify-center">
                    <PulseLoader color="#0074FC" />
                </div>
            ) : (
                profileData && (
                    <div className="bg-[#F6F7F8] h-auto z-10 py-10 space-y-16">
                        <div className="bg-white w-11/12 mx-auto rounded-[19px] flex items-center gap-10 px-14 py-10">
                            <div className="flex items-center justify-center bg-gradient-to-t from-[#2975FD] to-[#01DDB7] rounded-full w-[138px] h-[138px] text-[50px] text-white cursor-pointer">
                                {profileData['name']?.[0]}
                            </div>
                            <div className="space-y-3">
                                <h1 className="text-[25px] font-poppins font-[500]">
                                    {profileData['name']}
                                </h1>
                                <div className="flex items-center gap-10">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={profileViewMail}
                                            alt="profileView"
                                        />
                                        <p className="text-[#6C7587] font-poppins font-[500] text-[18px]">
                                            {profileData['email']}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={profileViewCall}
                                            alt="profileView"
                                        />
                                        <p className="text-[#6C7587] font-poppins font-[500] text-[18px]">
                                            {profileData['contact_number']}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <a
                                        href={profileData['url']}
                                        target="_blank"
                                        className="text-[#2975FD] cursor-pointer"
                                        rel="noreferrer"
                                    >
                                        {profileData['url']}
                                    </a>
                                </div>
                            </div>
                        </div>
                        {profileData?.role === 'FL' && (
                            <div className="w-11/12 mx-auto space-y-4">
                                <h1 className="font-[600] font-poppins text-[25px] text-[#2580F5]">
                                    Skills
                                </h1>
                                <div className="flex items-center gap-4">
                                    {profileData['skills']?.map(
                                        (skill: any) => {
                                            return (
                                                <p className="p-2 px-5 text-[16px] font-poppins font-[500] bg-white text-black rounded-[20.5px]">
                                                    {skill}
                                                </p>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        )}
                        {profileData?.role === 'FL' && (
                            <div className="w-11/12 mx-auto space-y-4">
                                <h1 className="font-[600] font-poppins text-[25px] text-[#2580F5]">
                                    Resume
                                </h1>
                                <div className="flex items-center gap-4 rounded-[9px] bg-white p-2.5 px-4 py-4 max-w-max">
                                    <img
                                        src={profileViewResume}
                                        alt="profileViewResume"
                                    />
                                    <a
                                        href={profileData['resume_url']}
                                        target="_blank"
                                        className="text-[20px] text-black font-poppins font-[500]"
                                        rel="noreferrer"
                                    >
                                        {profileData['name']}_Resume.pdf
                                    </a>
                                </div>
                            </div>
                        )}
                        {profileData['achievements'] &&
                            profileData['achievements'].length && (
                                <div className="w-11/12 mx-auto space-y-4">
                                    <h1 className="font-[600] font-poppins text-[25px] text-[#2580F5]">
                                        Achievements
                                    </h1>
                                    {/* <p className="font-[400] font-poppins text-[20px]">
                                        {profileData['achievements']}
                                    </p> */}

                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: profileData['achievements'],
                                        }}
                                    />
                                </div>
                            )}
                        <div className="grid grid-cols-2 gap-3 w-11/12 mx-auto">
                            {profileData['corporate_training'] === true && (
                                <Benefits
                                    image={profileViewCA}
                                    text="Corporate Training"
                                />
                            )}
                            {profileData['placement_assistance'] === true && (
                                <Benefits
                                    image={profileViewCT}
                                    text="Placement Assistance"
                                />
                            )}
                            {profileData['certificate_assistance'] === true && (
                                <Benefits
                                    image={profileViewHS}
                                    text="Certification Assistance"
                                />
                            )}
                            {profileData['is_online_training'] === true && (
                                <Benefits
                                    image={profileViewPA}
                                    text="Online Training"
                                />
                            )}
                            {profileData['hands_on_lab_support'] === true && (
                                <Benefits
                                    image={profileViewOT}
                                    text="Hands-On lab Support"
                                />
                            )}
                            {profileData['iso_certification'] === true && (
                                <Benefits
                                    image={profileViewIC}
                                    profileViewIC
                                    text="ISO Certification"
                                />
                            )}
                        </div>
                    </div>
                )
            )}
        </>
    );
};

const Benefits = ({ image, text }: any) => {
    return (
        <div className="flex items-center gap-8 px-6 py-4 bg-white">
            <img src={image} alt={image} className="w-[60px] h-[60px]" />
            <div className="border-[0.5px] border-[#E6E6E6] h-[50px]"></div>
            <p className="font-[500] font-poppins text-[20px]">{text}</p>
        </div>
    );
};
