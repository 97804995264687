export const DurationLength = ({
    formik,
    value,
    check,
    onChange,
    ...rest
}: any) => {
    // console.log({value})
    const Options =
        check > 1
            ? ['hours', 'days', 'weeks', 'months']
            : ['hour', 'day', 'week', 'month'];
    return (
        <div className="h-[95px] flex items-end w-1/2">
            <select
                onChange={onChange}
                {...rest}
                className="h-[55px] rounded-[8px] border-[0.7px] border-black w-full px-3"
            >
                {Options.map((option: any, index: number) => {
                    return (
                        <option
                            key={index}
                            selected={
                                value.includes(option) || option.includes(value)
                            }
                        >
                            {option}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
