// import { useState } from 'react';
// import toast from 'react-hot-toast';
// import { convertedTimeToNumber } from './ConverterTimeToNumber';
// import { InputTag } from './InputTag';
// import closeBtn from '../../assets/Images/closeBtn.svg';
// import { dateFormat } from '../CMS/Role';
// import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

// export const BatchTimingLine = ({
//     batch_timings,
//     chooseDate,
//     setFieldValue,
//     isCustomizeCourse,
//     isEdit,
//     batch,
//     error,
// }: any) => {
//     const [startTime, setStartTime] = useState('');
//     const [endTime, setEndTime] = useState('');
//     const [currentlyInsertedBatchTimings, setCurrentlyInsertedBatchTimings] =
//         useState<string[]>([]);
//     // const {batch_timings}=formik.values;

//     const AddTime = () => {
//         if (!chooseDate.length) {
//             return toast.error(
//                 camelCaseConvertForErrorMessage('Select Date of Training')
//             );
//         }
//         const time = `${startTime}-${endTime}`;
//         if (!startTime || !endTime) {
//             return toast.error(camelCaseConvertForErrorMessage('Enter Time'));
//         }
//         // if(batch_timings.length>=3){
//         //     return toast.error("only three batches were allowed");
//         // }
//         if (convertedTimeToNumber(startTime) > convertedTimeToNumber(endTime)) {
//             return toast.error(
//                 camelCaseConvertForErrorMessage(
//                     'End Time Should be Greater than Start Time'
//                 )
//             );
//         }
//         if (
//             convertedTimeToNumber(startTime) === convertedTimeToNumber(endTime)
//         ) {
//             return toast.error(
//                 camelCaseConvertForErrorMessage('Time Should not be Equal')
//             );
//         }
//         if (
//             !batch_timings.filter(
//                 (bt: any) =>
//                     bt.batchDate === chooseDate && bt.batchTime === time
//             ).length
//         ) {
//             if (!isCustomizeCourse) {
//                 setCurrentlyInsertedBatchTimings([
//                     ...currentlyInsertedBatchTimings,
//                     chooseDate + time,
//                 ]);
//             }
//             setFieldValue('batch', { batchDate: chooseDate, batchTime: time });
//             return setFieldValue('batches', [
//                 ...batch_timings,
//                 { batchDate: chooseDate, batchTime: time },
//             ]);
//         } else {
//             toast.error(
//                 camelCaseConvertForErrorMessage('Batch Timing Already Added')
//             );
//         }
//     };

//     const removeTime = (bDate: string, btime: string) => {
//         const timings = [...batch_timings];
//         const batchTimings = timings.filter((bt: any) => {
//             if (bDate + btime !== bt.batchDate + bt.batchTime) {
//                 return bt;
//             }
//         });
//         setFieldValue('batches', batchTimings);
//     };

//     return (
//         <div className="mb-14">
//             <div className="flex justify-start gap-14">
//                 <InputTag
//                     name="current_batch_start"
//                     type={'time'}
//                     value={startTime}
//                     onChange={(e: any) => setStartTime(e.target.value)}
//                     label={'Batch Start Time'}
//                     className={'h-[55px] mt-4'}
//                     Tagwidth={'lg:w-1/5 xl:w-1/4 2xl:w-1/5'}
//                 />
//                 <InputTag
//                     name="current_batch_end"
//                     min={startTime}
//                     step={60}
//                     type={'time'}
//                     value={endTime}
//                     onChange={(e: any) => setEndTime(e.target.value)}
//                     label={'Batch End Time'}
//                     className={'h-[55px] mt-4'}
//                     Tagwidth={'lg:w-1/5 xl:w-1/4 2xl:w-1/5'}
//                 />
//                 <div className="h-[95px] flex items-end">
//                     <button
//                         type="button"
//                         className="bg-white w-[225px] h-[55px] rounded-[8px] text-[#085BEB] text-[15px] font-[600] border border-[#085BEB]"
//                         onClick={AddTime}
//                     >
//                         ADD
//                     </button>
//                 </div>
//             </div>
//             {batch_timings.length > 0 && (
//                 <div>
//                     <label className="text-[15px] font-[500]">Batches</label>

//                     <div className="flex space-x-4 py-3 bg-white rounded-[8px] relative overflow-x-auto overflow-y-hidden items-center px-4 mt-4">
//                         {/* <div className="flex space-x-4 "> */}
//                         {batch_timings.map(
//                             ({ batchDate, batchTime }: any, index: number) => {
//                                 return (
//                                     <div
//                                         key={index}
//                                         className={`py-2 px-4 flex items-center space-x-3 rounded-[17px] bg-[#F8F8F8] ${
//                                             isCustomizeCourse &&
//                                             batch.batchDate +
//                                                 batch.batchTime ===
//                                                 batchDate + batchTime &&
//                                             'text-white bg-[#065FEF]'
//                                         }
//                                         ${isCustomizeCourse && 'cursor-pointer'}
//                                     `}
//                                         onClick={() =>
//                                             isCustomizeCourse &&
//                                             setFieldValue('batch', {
//                                                 batchDate,
//                                                 batchTime,
//                                             })
//                                         }
//                                         tabIndex={0}
//                                     >
//                                         <p
//                                             className={`${
//                                                 !isEdit
//                                                     ? 'w-[210px]'
//                                                     : 'w-[240px] text-center'
//                                             }`}
//                                             tabIndex={0}
//                                         >
//                                             {dateFormat(batchDate) +
//                                                 ' ' +
//                                                 `(${batchTime})`}
//                                         </p>
//                                         {
//                                             // currentlyInsertedBatchTimings.includes(
//                                             // batchDate + batchTime)
//                                             !isCustomizeCourse && (
//                                                 <img
//                                                     src={closeBtn}
//                                                     alt="closeBtn"
//                                                     className="h-[12px] cursor-pointer"
//                                                     onClick={() =>
//                                                         removeTime(
//                                                             batchDate,
//                                                             batchTime
//                                                         )
//                                                     }
//                                                 />
//                                             )
//                                         }
//                                     </div>
//                                 );
//                             }
//                         )}
//                         {/* </div> */}
//                     </div>
//                     {error && (
//                         <p className="text-red-500 text-[12px] mt-1">{error}</p>
//                     )}
//                 </div>
//             )}
//         </div>
//     );
// };

import { useState } from 'react';
import toast from 'react-hot-toast';
import { convertedTimeToNumber } from './ConverterTimeToNumber';
import { InputTag } from './InputTag';
import closeBtn from '../../assets/Images/closeBtn.svg';
import { dateFormat } from '../CMS/Role';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const BatchTimingLine = ({
    batch_timings,
    chooseDate,
    setFieldValue,
    isCustomizeCourse,
    isEdit,
    batch,
    error,
    roleSelection,
}: any) => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [currentlyInsertedBatchTimings, setCurrentlyInsertedBatchTimings] =
        useState<string[]>([]);

    const AddTime = () => {
        if (!chooseDate.length) {
            return toast.error(
                camelCaseConvertForErrorMessage('Select Date of Training')
            );
        }
        const time = `${startTime}-${endTime}`;
        if (!startTime || !endTime) {
            return toast.error(camelCaseConvertForErrorMessage('Enter Time'));
        }
        if (convertedTimeToNumber(startTime) > convertedTimeToNumber(endTime)) {
            return toast.error(
                camelCaseConvertForErrorMessage(
                    'End Time Should be Greater than Start Time'
                )
            );
        }
        if (
            convertedTimeToNumber(startTime) === convertedTimeToNumber(endTime)
        ) {
            return toast.error(
                camelCaseConvertForErrorMessage('Time Should not be Equal')
            );
        }
        if (
            !batch_timings.filter(
                (bt: any) =>
                    bt.batchDate === chooseDate && bt.batchTime === time
            ).length
        ) {
            if (!isCustomizeCourse) {
                setCurrentlyInsertedBatchTimings([
                    ...currentlyInsertedBatchTimings,
                    chooseDate + time,
                ]);
            }
            setFieldValue('batch', { batchDate: chooseDate, batchTime: time });
            return setFieldValue('batches', [
                ...batch_timings,
                { batchDate: chooseDate, batchTime: time },
            ]);
        } else {
            toast.error(
                camelCaseConvertForErrorMessage('Batch Timing Already Added')
            );
        }
    };

    const removeTime = (bDate: string, btime: string) => {
        const timings = [...batch_timings];
        const batchTimings = timings.filter((bt: any) => {
            if (bDate + btime !== bt.batchDate + bt.batchTime) {
                return bt;
            }
        });
        setFieldValue('batches', batchTimings);
    };

    // Filter batch timings based on roleSelection
    const filteredBatchTimings = roleSelection
        ? batch_timings
        : batch_timings.filter((bt: any) => bt.batchDate === chooseDate);

    return (
        <div className="mb-14">
            <div className="flex justify-start gap-14">
                <InputTag
                    name="current_batch_start"
                    type={'time'}
                    value={startTime}
                    onChange={(e: any) => setStartTime(e.target.value)}
                    label={'Batch Start Time'}
                    className={'h-[55px] mt-4'}
                    Tagwidth={'lg:w-1/5 xl:w-1/4 2xl:w-1/5'}
                />
                <InputTag
                    name="current_batch_end"
                    min={startTime}
                    step={60}
                    type={'time'}
                    value={endTime}
                    onChange={(e: any) => setEndTime(e.target.value)}
                    label={'Batch End Time'}
                    className={'h-[55px] mt-4'}
                    Tagwidth={'lg:w-1/5 xl:w-1/4 2xl:w-1/5'}
                />
                <div className="h-[95px] flex items-end">
                    <button
                        type="button"
                        className="bg-white w-[225px] h-[55px] rounded-[8px] text-[#085BEB] text-[15px] font-[600] border border-[#085BEB]"
                        onClick={AddTime}
                    >
                        ADD
                    </button>
                </div>
            </div>
            {filteredBatchTimings.length > 0 && (
                <div>
                    <label className="text-[15px] font-[500]">Batches</label>
                    <div className="flex space-x-4 py-3 bg-white rounded-[8px] relative overflow-x-auto overflow-y-hidden items-center px-4 mt-4">
                        {filteredBatchTimings.map(
                            ({ batchDate, batchTime }: any, index: number) => (
                                <div
                                    key={index}
                                    className={`py-2 px-4 flex items-center space-x-3 rounded-[17px] bg-[#F8F8F8] ${
                                        isCustomizeCourse &&
                                        batch.batchDate + batch.batchTime ===
                                            batchDate + batchTime &&
                                        'text-white bg-[#065FEF]'
                                    }
                                ${isCustomizeCourse && 'cursor-pointer'}`}
                                    onClick={() =>
                                        isCustomizeCourse &&
                                        setFieldValue('batch', {
                                            batchDate,
                                            batchTime,
                                        })
                                    }
                                    tabIndex={0}
                                >
                                    <p
                                        className={`${
                                            !isEdit
                                                ? 'w-[210px]'
                                                : 'w-[240px] text-center'
                                        }`}
                                        tabIndex={0}
                                    >
                                        {dateFormat(batchDate) +
                                            ' ' +
                                            `(${batchTime})`}
                                    </p>
                                    {!isCustomizeCourse && (
                                        <img
                                            src={closeBtn}
                                            alt="closeBtn"
                                            className="h-[12px] cursor-pointer"
                                            onClick={() =>
                                                removeTime(batchDate, batchTime)
                                            }
                                        />
                                    )}
                                </div>
                            )
                        )}
                    </div>
                    {error && (
                        <p className="text-red-500 text-[12px] mt-1">{error}</p>
                    )}
                </div>
            )}
        </div>
    );
};
