import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { readRecord, updateRecord } from '../../Services/backend/apiCalls';
import { PulseLoader } from 'react-spinners';
import { useFormik } from 'formik';
import {
    profileForST,
    profileForCO,
    profileForFL,
    profileForTI,
} from '../../Services/Formik/Formik';
import toast from 'react-hot-toast';
import { useAppSelector, useAppDispatch } from '../..';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
import { ProfileData } from './ProfileData';
import { inputForInitialValues } from './FormikProfile';
import { useDebounce } from '../../hooks/DebounceHook';
import { storage } from '../../Firebase/firebase';
import { deleteObject } from 'firebase/storage';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { saveProfile } from '../../Redux/Reducers/comparecoursesReducers';
import { FaEdit } from 'react-icons/fa';
import closeBtn from '../../assets/Images/closeBtn.svg';

export const Profile = () => {
    const { id } = useParams();
    const isViewable = id ? true : false;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [resumeStatus, setResumeStatus] = useState('');
    const [singleUserEmail, setSingleUserEmail] = useState('');
    const [singleUserRole, setSingleUserRole] = useState('');
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [citySelected, setCitySelected] = useState('bangalore');
    const [places, setPlaces] = useState([]);
    const [dropdownVal, setDropdownVal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDisable, setIsDisable] = useState(true);
    const [count, setCount] = useState(0);

    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [profilePicStatus, setProfilePicStatus] = useState('');

    const [newAchievementSave, setNewAchievementSave] = useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const {
        uid: userUID,
        email,
        role,
        token,
    } = useAppSelector(({ signin }: any) => signin['signinValue']);
    const uid = id ? id : userUID;
    const [initialValues, setInitialValues] = useState(
        inputForInitialValues[role]
    );
    // const [updatedValues, setUpdatedValues] = useState(initialValues);

    const CityInfo = (e: any) => {
        setCitySelected(e.target.value);
        formik.setFieldValue('address_line', '');
        setDropdownVal(false);
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            (async () => {
                setDropdownVal(searchTerm.length >= 3 ? true : false);
                formik.setFieldValue('city', citySelected);
                const SuggesionInput = await readRecord(
                    { input: debouncedSearchTerm, city: citySelected },
                    'placesuggestion'
                );
                console.log(
                    SuggesionInput,
                    'SuggesionInput',
                    citySelected,
                    debouncedSearchTerm
                );
                if (SuggesionInput.status) {
                    setPlaces(SuggesionInput.places);
                } else {
                    toast.error(SuggesionInput.message);
                }
            })();
        }
    }, [debouncedSearchTerm]);

    const selectedInputValue = (val: any, id: any) => {
        setDropdownVal(false);
        if (role === 'TI') {
            formik.setFieldValue('branch_id', id);
        }
        // update
        let words = val.trim().split(', ');

        // const getValue = val;
        const country = words[words.length - 1];
        console.log(country, 'country');

        formik.setFieldValue('country', country);
        const state = words[words.length - 2];
        formik.setFieldValue('state', state);
        console.log('state', state);

        formik.setFieldValue('address_line', val);
    };

    const updateProfile = async () => {
        // setLoader(true);
        const { achievements, skills, gst_number, ...rest } = formik.values;

        // Check if profilePicture is null and set it to an empty object if true
        const updatedProfilePicture = profilePicture || {
            url: '',
            name: '',
            key: '',
        };

        const body =
            role === 'TI' || role === 'FL'
                ? {
                      ...(role === 'FL' && { skills: [skills] }),
                      ...(role === 'TI' && { gst_number: gst_number }),
                      // gst_number: gst_number,
                      achievements: achievements,
                      ...rest,
                      profilePicture: updatedProfilePicture, // Ensure profilePicture is not null
                  }
                : {
                      ...rest,
                      profilePicture: updatedProfilePicture, //// Ensure profilePicture is not null
                  };
        console.log(body, 'bodyyyy');

        // console.log(body, "body")
        const changeProfileDetails = await updateRecord(body, 'profiles');
        // console.log(changeProfileDetails, changeProfileDetails.message, "Message")
        console.log(changeProfileDetails, 'changeProfileDetails');

        if (changeProfileDetails.status) {
            toast.success('Profile details updated successfully');
            setInitialValues(values);
            dispatch(saveProfile(true));
        } else {
            toast.error(changeProfileDetails.message);
            console.log('dfdfdffdfdfd');
        }
        setIsDisable(true);
        // setLoader(false);
    };

    const getProfile = async () => {
        setLoading(true);
        const signedProfile = await readRecord({ uid }, 'user');
        console.log(signedProfile, 'signedProfile');

        // console.log(signedProfile, "signedProfile")
        if (signedProfile.status) {
            const {
                role,
                email,
                branch_id,
                corporate_training,
                placement_assistance,
                certificate_assistance,
                is_online_training,
                hands_on_lab_support,
                iso_certification,
                name,
                url,
                industry,
                resume,
                // resume_url,
                account_holder_name,
                account_number,
                bank_name,
                branch,
                ifsc_number,
                contact_number,
                city,
                country,
                state,
                address_line,
                skills,
                achievements,
                gst_number,
                profilePicture,
            } = signedProfile.data;
            // setUpdatedValues(signedProfile.data);
            setSingleUserEmail(email);
            setSingleUserRole(role);
            dispatch(
                setSignValue({
                    ...setSignValue,
                    place_id: branch_id,
                    username: name,
                })
            );
            if (role === 'CO') {
                setFieldValue('company_url', url);
            } else if (role === 'FL') {
                setFieldValue('industry', industry);
                setFieldValue('linkedin_url', url);
                setFieldValue('resume', resume);
                // setFieldValue('resume_url', resume_url);
                setFieldValue('profilePicture', profilePicture);
                setFieldValue('corporate_training', corporate_training);
                setFieldValue('placement_assistance', placement_assistance);
                setFieldValue('certificate_assistance', certificate_assistance);
                setFieldValue('is_online_training', is_online_training);
                setFieldValue('hands_on_lab_support', hands_on_lab_support);
                setFieldValue('iso_certification', iso_certification);
                setFieldValue('account_holder_name', account_holder_name || '');
                setFieldValue('account_number', account_number || '');
                setFieldValue('bank_name', bank_name || '');
                setFieldValue('branch', branch || '');
                setFieldValue('ifsc_number', ifsc_number || '');
                setFieldValue('skills', skills.join(','));
                setFieldValue('achievements', achievements || '');
                setFieldValue('contact_number', contact_number);
                setFieldValue('address_line', address_line);
                setFieldValue('name', name);
            } else if (role === 'TI') {
                setFieldValue('industry', industry);
                setFieldValue('url', url);
                setFieldValue('corporate_training', corporate_training);
                setFieldValue('placement_assistance', placement_assistance);
                setFieldValue('certificate_assistance', certificate_assistance);
                setFieldValue('is_online_training', is_online_training);
                setFieldValue('hands_on_lab_support', hands_on_lab_support);
                setFieldValue('iso_certification', iso_certification);
                setFieldValue('gst_number', gst_number || '');
                setFieldValue('account_holder_name', account_holder_name || '');
                setFieldValue('account_number', account_number || '');
                setFieldValue('bank_name', bank_name || '');
                setFieldValue('branch', branch || '');
                setFieldValue('ifsc_number', ifsc_number || '');
                setFieldValue('achievements', achievements || '');
            }
            setFieldValue('name', name);
            setFieldValue('contact_number', contact_number);
            setFieldValue('city', city);
            setFieldValue('country', country);
            setFieldValue('state', state);
            setFieldValue('address_line', address_line);
            setCitySelected(city);
            setFieldValue('profilePicture', profilePicture);
        } else toast.error(signedProfile.message);
    };

    useEffect(() => {
        getProfile();
    }, []);

    const roleForSchema: any = {
        CO: profileForCO,
        ST: profileForST,
        TI: profileForTI,
        FL: profileForFL,
    };

    const formik = useFormik({
        initialValues,
        validationSchema: roleForSchema[role],
        onSubmit: () => {
            updateProfile();
        },
    });

    const { values, handleSubmit, setFieldValue } = formik;
    console.log(values, 'values');

    const {
        address_line,
        city,
        contact_number,
        country,
        name,
        state,
        account_holder_name,
        account_number,
        ifsc_number,
        bank_name,
        branch,
        achievements,
        certificate_assistance,
        corporate_training,
        hands_on_lab_support,
        is_online_training,
        iso_certification,
        placement_assistance,
        company_url,
        industry,
        gst_number,
        url,
        linkedin_url,
        skills,
        resume,
        profilePicture,
    } = values;

    useEffect(() => {
        if (
            count > 1 &&
            (address_line ||
                city ||
                contact_number ||
                country ||
                name ||
                state ||
                account_holder_name ||
                account_number ||
                ifsc_number ||
                bank_name ||
                branch ||
                achievements ||
                certificate_assistance ||
                corporate_training ||
                hands_on_lab_support ||
                is_online_training ||
                iso_certification ||
                placement_assistance ||
                company_url ||
                industry ||
                gst_number ||
                url ||
                linkedin_url ||
                skills ||
                resume ||
                profilePicture)
        ) {
            setIsDisable(false);
        }
        setCount(count + 1);
    }, [
        address_line,
        city,
        contact_number,
        country,
        name,
        state,
        account_holder_name,
        account_number,
        ifsc_number,
        bank_name,
        branch,
        achievements,
        certificate_assistance,
        corporate_training,
        hands_on_lab_support,
        is_online_training,
        iso_certification,
        placement_assistance,
        company_url,
        industry,
        gst_number,
        url,
        linkedin_url,
        skills,
        resume,
        profilePicture,
    ]);
    const keyObject: any = Object.keys(formik.errors).length;

    const uploadResumeFile = async (e: any) => {
        const file = e.target.files[0];
        const fileName = `${file?.name}_${uid}` + '_resume';
        // console.log(file, "FILE")
        if (!e.target.files.length) {
            return;
        }
        setResumeStatus('uploading...');
        const storageRef = ref(storage, `/resumes/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            (snapshot: any) => {},
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    // console.log(url, file?.name, fileName, "fileName");
                    formik.setFieldValue('resume', {
                        key: fileName,
                        name: file?.name,
                        url: url,
                    });
                    // formik.setFieldValue('resume_name', file.name);
                    setResumeStatus(file.name);
                    // toast.success("uploaded successfully");
                });
            }
        );
    };

    const deleteFile = async () => {
        setResumeStatus('deleting...');

        const { resume, skills, ...rest } = values;
        const fileName = `${resume?.name}_${uid}` + '_resume';
        const deleteStorageRef = ref(storage, `/resumes/${fileName}`);
        deleteObject(deleteStorageRef)
            .then(async () => {
                // formik.setFieldValue('resume_url', '');
                formik.setFieldValue('resume', {
                    key: '',
                    name: '',
                    url: '',
                });
                setResumeStatus('');
            })
            .catch((err) => {
                console.log('error occurred', err);
            });
        const body = {
            resume: {
                key: '',
                name: '',
                url: '',
            },
            skills: [skills],
            ...rest,
        };
        const changeProfileDetails = await updateRecord(body, 'profiles');
        if (changeProfileDetails) {
            toast.success('Resume Deleted');
        }
    };

    const uploadProfilePicture = async (e: any) => {
        const file = e.target.files[0];
        const fileName = `${file?.name}_${uid}` + '_profile';
        if (!e.target.files.length) {
            return;
        }
        setProfilePicStatus('uploading...');
        const storageRef = ref(storage, `/profiles/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            (snapshot: any) => {},
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    // get download url
                    console.log('ddss', url, file?.name, fileName, 'fileName');
                    formik.setFieldValue('profilePicture.url', url);
                    formik.setFieldValue('profilePicture.name', file?.name);
                    formik.setFieldValue('profilePicture.key', fileName);
                    setProfilePicStatus(file.name);
                });
            }
        );
    };

    const deleteProfilePicture = async (fileName: string) => {
        setProfilePicStatus('deleting...');

        const deleteStorageRef = ref(
            storage,
            `/profiles/${fileName + '_' + uid + '_profile'}`
        );

        deleteObject(deleteStorageRef)
            .then(async () => {
                formik.setFieldValue('profilePicture', {
                    url: '',
                    name: '',
                    key: '',
                });
                setProfilePicStatus('');
            })
            .catch((err) => {
                console.log('error occurred', err);
            });
    };

    return (
        <>
            {!loading ? (
                <div className="flex w-full min-h-[450px] items-center justify-center border">
                    <PulseLoader color="#0074FC" />
                </div>
            ) : (
                <div className="bg-[#F6F7F8] h-auto z-10">
                    <form className="w-11/12 mx-auto" onSubmit={handleSubmit}>
                        <div className="flex justify-between items-end w-11/12 mx-auto bg-transparent mt-[-100px] ">
                            <div className="flex items-end gap-6 px-12">
                                <div className="relative flex">
                                    {values.profilePicture &&
                                    values.profilePicture.url ? (
                                        <img
                                            src={values.profilePicture.url}
                                            className="w-[195px] h-[195px] rounded-full object-cover "
                                            alt=""
                                        />
                                    ) : (
                                        <p className="w-[195px] h-[195px] rounded-full bg-[#F7B226] border-4 border-white flex items-center justify-center text-[100px] text-white ">
                                            {' '}
                                            {values.name[0]}
                                            {console.log(values.profilePicture)}
                                        </p>
                                    )}

                                    <p className="absolute right-8 bottom-[-10px] ">
                                        <ProfilePicture
                                            uploadProfilePicture={
                                                uploadProfilePicture
                                            }
                                            profilePictureStatus={
                                                profilePicStatus
                                            }
                                            formik={formik}
                                            deleteProfilePicture={
                                                deleteProfilePicture
                                            }
                                        />
                                    </p>
                                    {/* <ProfilePicture
                                        uploadProfilePicture={
                                            uploadProfilePicture
                                        }
                                        profilePictureStatus={profilePicStatus}
                                        formik={formik}
                                        deleteProfilePicture={
                                            deleteProfilePicture
                                        }
                                    /> */}
                                    {/* <img src={overwrite} alt="Overwrite" className='absolute right-4 bottom-0' /> */}
                                </div>
                                <div>
                                    <h1 className="text-[26px] font-[600]">
                                        {values.name}
                                    </h1>
                                    <p className="text-[18px] font-[300] text-[#858585]">
                                        {isViewable ? singleUserEmail : email}
                                    </p>
                                </div>
                            </div>
                            {/* <div> */}
                            {isViewable && (
                                <button
                                    className={`bg-[#2580F5] text-white text-[15px] font-[400] rounded-[9px] p-3 px-6 cursor-pointer`}
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </button>
                            )}
                            {!isViewable &&
                                (loader ? (
                                    <div className="bg-[#2580F5] rounded-[9px] w-[88px] h-[42px] flex justify-center items-center">
                                        <PulseLoader color="#ffffff" size={8} />
                                    </div>
                                ) : (
                                    <button
                                        className={`cursor-pointer ${
                                            isDisable
                                                ? `bg-gray-300 text-white text-[12px] font-[400] p-2.5 px-6 rounded-[9px] w-[88px] h-[42px] disabled:opacity-50`
                                                : `bg-[#2580F5] cursor-pointer text-white text-[15px] font-[400] rounded-[9px] w-[88px] h-[42px] disabled:opacity-50`
                                        }`}
                                        disabled={isDisable}
                                        //   disabled={keyObject || lodash.isEqual(values, initialValues)}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                ))}
                            {/* </div> */}
                        </div>
                        <div className="w-11/12 mx-auto min-h-screen">
                            <ProfileData
                                id={id}
                                formik={formik}
                                role={role}
                                singleUserRole={singleUserRole}
                                isViewable={isViewable}
                                email={isViewable ? singleUserEmail : email}
                                citySelected={citySelected}
                                setCitySelected={setCitySelected}
                                places={places}
                                dropdownVal={dropdownVal}
                                selectedInputValue={selectedInputValue}
                                search={setSearchTerm}
                                CityInfo={CityInfo}
                                resumefile={uploadResumeFile}
                                deleteFile={deleteFile}
                                resumeStatus={resumeStatus}
                                newAchievementSave={newAchievementSave}
                            />
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export const ProfilePicture = ({
    uploadProfilePicture,
    profilePictureStatus,
    deleteProfilePicture,
    formik,
}: any) => {
    return (
        <div className="mt-2">
            <label className="text-[14px] xl:text-[18px] font-[400]">
                {/* Profile Picture */}
            </label>
            <div className="flex items-center mt-2">
                <input
                    type="file"
                    accept="image/*"
                    onChange={uploadProfilePicture}
                    className="hidden"
                    id="profilePicture"
                />
                <label
                    htmlFor="profilePicture"
                    // className="cursor-pointer bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-2 text-white rounded-[8px] text-[12px] font-[300]"
                >
                    <FaEdit
                        className="text-blue-500 mr-2 cursor-pointer"
                        size="20"
                    />
                </label>
                {profilePictureStatus && (
                    <div className="flex items-center ">
                        {/* <span className="text-[14px]">
                            {profilePictureStatus}
                        </span> */}
                        <img
                            src={closeBtn}
                            alt="Delete"
                            className="ml-2 cursor-pointer"
                            onClick={() =>
                                deleteProfilePicture(
                                    formik.values.profilePicture.name
                                )
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
