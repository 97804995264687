import { useCallback, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash'; // lodash's debounce function
import { Editor } from '@tiptap/core';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';

import {
    FaBold,
    FaHeading,
    FaItalic,
    FaListOl,
    FaListUl,
    FaQuoteLeft,
    FaRedo,
    FaStrikethrough,
    FaUnderline,
    FaUndo,
} from 'react-icons/fa';

import {
    useNavigate,
    useParams,
    useLocation,
    createSearchParams,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../..';
import { useFormik } from 'formik';
import { addCourses, customizeCourses } from '../../Services/Formik/Formik';
import {
    postRecord,
    readRecord,
    updateRecord,
} from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { storage } from '../../Firebase/firebase';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';
import { parseFileNameFromStorageUrl } from '../ViewCourse/StorageUrlFileName';
import { DayField } from '../Elements/DayFeild';
import {
    daysTypeConversion,
    getTomorrowDate,
    isTrainer,
    onKeyDown,
} from '../../Services/commonFunctions';
import { DurationLength } from './DurationLength';
import { PulseLoader } from 'react-spinners';
import { FieldsLeft } from './CourseFieldsLeft';
import { FieldsRight } from './CourseFieldsRight';
import { BatchTimingLine } from './TagsForSelectTime';
import { RadioInput } from './RadioInput';
import { InputTag } from './InputTag';
import { RandomString } from './RandomString';
import { Tags } from '../Elements/Tags';
import { DateAndBatchTimings } from './DateAndBatchTimings';
import { useDispatch } from 'react-redux';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';

export const AddCourse = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    const isCustomizeCourse = location.pathname.includes('customize');
    const params = useParams();
    const { id }: any = params;
    const navigate = useNavigate();
    const submitButtonRef = useRef<any>();
    const [newState, setNewState] = useState<any>([]);
    const [previewUrl, setPreviewURL] = useState('');
    const [imageURL, setImageURL] = useState(false);
    const [resumeStatus, setResumeStatus] = useState('Click Here');
    // const [datesArray, setDatesArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showStateOfPercent, setShowStateOfPercent] = useState(false);
    const [title, setTitle] = useState('');
    const { token, role, attendedCourses, directRequestedIds } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    const roleSelection = isTrainer(role);

    const [editorContent, setEditorContent] = useState('');

    const input: any = {
        title: '',
        duration_count: '',
        duration_type: 'day',
        fee: '0',
        currency_code: 'INR',
        discount_percentage: '',
        highlights: '',
        image: '',
        description: '',
        session: '',
        tags: [],
        batches: [],
        mode: 'offline',
        isactive: true,
        certification_guidance: true,
        discount_available: true,
        ...(isCustomizeCourse && { batch: '' }),
    };
    // const [initialValues, setInitialValues] = useState(input);
    // const roleSelection = ['FL','CO','ST'].includes(role);

    const onChangeInputValue = (value: any, detail: any) => {
        // setAddCourse({ ...addCourse, [value]: detail })
        setFieldValue(value, detail);
        if (value === 'discount_available') {
            setShowStateOfPercent(detail);
        }
        if (value === 'discount_available' && !detail) {
            setFieldValue('discount_percentage', '');
        }
    };

    const getEditRequest = async () => {
        const CourseDetail = await readRecord({ id }, 'coursequery');
        if (CourseDetail.status) {
            const {
                title,
                description,
                fee,
                duration,
                batch_timings,
                highlights,
                image,
                discount_percentage,
                currency_code,
                isactive,
                batches,
                certification_guidance,
                discount_available,
                tags,
            } = CourseDetail.data[0];
            setFieldValue('title', title);
            setFieldValue('description', description);
            setFieldValue('fee', Number(fee));
            setFieldValue('duration_count', duration.split(' ')[0]);
            setFieldValue('duration_type', duration.split(' ')[1]);
            setFieldValue(
                'batch_timings',
                isCustomizeCourse ? [] : batch_timings
            );
            setFieldValue('highlights', highlights);
            setFieldValue('batch', batches[0]);
            // setFieldValue("date_of_training", start_date.split("T")[0]);
            setFieldValue('batches', batches);
            setFieldValue('image', image);
            discount_available &&
                setFieldValue(
                    'discount_percentage',
                    Number(discount_percentage)
                );
            setPreviewURL(image);
            setImageURL(true);
            setFieldValue('currency_code', currency_code);
            // setAddCourse({
            //     ...addCourse,
            //     mode: CourseDetail.data[0]["mode_of_teaching"]["mode"],
            //     isactive: isactive,
            //     certification_guidance: certification_guidance,
            //     discount_available: discount_available
            // });
            setFieldValue(
                'mode',
                CourseDetail.data[0]['mode_of_teaching']['mode']
            );
            setFieldValue('isactive', isactive);
            setFieldValue('certification_guidance', certification_guidance);
            setFieldValue('discount_available', discount_available);
            setShowStateOfPercent(discount_available);
            setFieldValue('tags', tags);
            setFieldValue(
                'session',
                CourseDetail.data[0]['mode_of_teaching']['session']
            );
            setNewState(CourseDetail.data[0]['mode_of_teaching']['session']);
        } else {
            // console.log(CourseDetail);
        }
    };

    useEffect(() => {
        id && getEditRequest();
    }, []);

    const customizeRequest = async () => {
        setLoading(true);
        submitButtonRef.current.disabled = true;
        if (values.batch === '') {
            return setFieldError('batch', 'Select one batch');
        }
        const body = {
            course_id: Number(id),
            mode_of_teaching: {
                session: values.session,
                mode: values.mode,
            },
            isCustomized: true,
            duration: {
                duration_count: Number(values.duration_count),
                duration_type: daysTypeConversion(
                    Number(values.duration_count),
                    values.duration_type
                ),
            },
            batch_timing: values.batch.batchTime,
            date_of_training: values.batch.batchDate,
        };
        const customizeCourse = await postRecord(body, 'directrequests');
        if (customizeCourse.status) {
            navigate({
                pathname: `/booknow/${values.title}/${id}`,
                search: createSearchParams({
                    dr_id: customizeCourse.data.id,
                }).toString(),
            });
            toast.success(customizeCourse.message);
            dispatch(
                setSignValue({
                    directRequestedIds: [...directRequestedIds, +id],
                })
            );
        } else if (customizeCourse.message === 'Your Request is Pending') {
            // toast.error(customizeCourse.message);
            toast.error('Your customized request is pending');
            navigate(`/course/view/${values.title}/${id}`);
        } else toast.error(customizeCourse.message);
        submitButtonRef.current.disabled = false;
        setLoading(false);
    };

    const handleClick = async () => {
        // setLoading(true);
        // submitButtonRef.current.disabled = true;
        setTitle(values.title);
        const {
            duration_count,
            duration_type,
            session,
            date_of_training,
            discount_percentage,
            batch_timings,
            mode,
            discount_available,
            batch,
            ...rest
        }: any = values;
        const body = {
            ...(id && { id: Number(id) }),
            ...rest,
            fee: Number(values.fee),
            discount_available: discount_available,
            ...(discount_available && {
                discount_percentage: Number(discount_percentage),
            }),
            duration: {
                duration_count: Number(duration_count),
                duration_type: daysTypeConversion(
                    Number(duration_count),
                    duration_type
                ),
            },
            mode_of_teaching: {
                session: session,
                mode: mode,
            },
        };
        // console.log(body, "BODY")
        if (discount_available && discount_percentage === '') {
            submitButtonRef.current.disabled = false;
            setLoading(false);
            toast.error('Please Enter Percentage value');
        } else {
            const coursesAPICall = await (id
                ? updateRecord(body, 'courses')
                : postRecord(body, 'courses'));
            if (coursesAPICall.status) {
                toast.success(coursesAPICall.message);
                // console.log({id});
                navigate(
                    `${
                        id
                            ? `/course/view/${values.title}/${id}`
                            : '/request/mycourse'
                    }`,
                    {
                        state: !id && 'typeForHeaderClick',
                    }
                );
                resetForm();
            } else toast.error(coursesAPICall.message);
            submitButtonRef.current.disabled = false;
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: input,
        validationSchema: isCustomizeCourse
            ? customizeCourses
            : addCourses(showStateOfPercent),
        onSubmit: () => {
            role === 'TI' || role === 'FL' ? handleClick() : customizeRequest();
        },
    });
    const {
        values,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldError,
        errors,
        resetForm,
    } = formik;
    const errorValue: any = errors.description;

    const uploadResumeFile = async (files: any) => {
        const randomKey = RandomString();
        setImageURL(true);
        const fileName = title + randomKey + '_coverImage';
        const file = Array.isArray(files) ? files[0] : files;
        setResumeStatus('uploading...');
        const storageRef = ref(storage, `/image/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            (snapshot: any) => {},
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setPreviewURL(url);
                    setFieldValue('image', url);
                    setResumeStatus(file.name);
                    toast.success('uploaded successfully');
                });
            }
        );
    };

    const deleteUploadedImage = async (url: string) => {
        setImageURL(true);
        if (role === 'TI' || role === 'FL') {
            const fileName = parseFileNameFromStorageUrl(url);
            const deleteStorageRef = ref(storage, `/image/${fileName}`);
            deleteObject(deleteStorageRef)
                .then(() => {
                    setPreviewURL('');
                    setResumeStatus('Click Here');
                    setFieldValue('image', '');
                    toast.success(`${title} deleted successfully`);
                    setImageURL(false);
                })
                .catch((err) => {
                    console.log('error occurred');
                });
        }
    };

    const DayData = (day: any) => {
        if (newState.includes(day)) {
            const A = [...newState];
            const B = A.filter((b: any) => b !== day);
            setFieldValue('session', [...B]);
            setNewState([...B]);
        } else {
            setFieldValue('session', [...newState, day]);
            setNewState([...newState, day]);
        }
    };
    const keyObject: any = Object.keys(formik.errors).length;

    const handleDate = (e: any) => {
        // console.log(e, "duration_type");
        handleChange(e);
        setFieldValue('duration_type', e.target.value);
    };

    // const handleKeyDown = (event: any) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //         setFieldValue('description', values.description + '\n');
    //     }
    // };

    // new fun update by me
    // const handleKeyDown = (event: any) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //         const newDescription = values.description + '\n\u2022 ';
    //         formik.setFieldValue('description', newDescription);
    //     } else if (
    //         event.key === 'Backspace' &&
    //         values.description.endsWith('\n\u2024 ')
    //     ) {
    //         const updatedDescription = values.description.slice(0, -3);
    //         formik.setFieldValue('description', updatedDescription);
    //     } else if (event.key === ' ' && values.description.endsWith('\n')) {
    //         const updatedDescription =
    //             values.description.slice(0, -1) + '\u2022 ';
    //         formik.setFieldValue('description', updatedDescription);
    //     } else if (values.description === '') {
    //         formik.setFieldValue('description', '\u2022 ');
    //     }
    // };
    // Update the handleKeyDown function to include bullet point and paragraph break logic(by me)
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const textarea = event.target as HTMLTextAreaElement;
            const selectionStart = textarea.selectionStart;
            const selectionEnd = textarea.selectionEnd;
            const textBefore = values.description.substring(0, selectionStart);
            const textAfter = values.description.substring(selectionEnd);
            const newText = textBefore + '\n• ' + textAfter; // Add a bullet point at the beginning of the new line
            setFieldValue('description', newText); // Update the description field value
            textarea.setSelectionRange(selectionStart + 3, selectionStart + 3); // Move the cursor after the bullet point
        } else if (
            event.key === 'Backspace' &&
            values.description.endsWith('\n• ') &&
            values.description.lastIndexOf('\n• ') ===
                values.description.length - 3
        ) {
            // Remove the bullet point at the end of the line
            event.preventDefault();
            const updatedDescription = values.description.slice(0, -3);
            setFieldValue('description', updatedDescription); // Update the description field value
        } else if (event.key === ' ' && values.description.endsWith('\n')) {
            // Add a bullet point at the beginning of the new line if there is a space at the end
            event.preventDefault();
            const updatedDescription = values.description.slice(0, -1) + '\n• ';
            setFieldValue('description', updatedDescription); // Update the description field value
        } else if (values.description === '') {
            // Start the description with a bullet point if it's empty
            setFieldValue('description', '• ');
        }
    };
    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        const pastedText =
            event.clipboardData?.getData('text') ||
            (event.target as HTMLTextAreaElement).value;
        const lines = pastedText.split('\n');
        const newText = lines.map((line) => '• ' + line).join('\n');
        setFieldValue('description', newText); // Update the description field value
    };

    const editor = useEditor({
        extensions: [StarterKit, Underline],
        content: '', // Initializes with the description field's content from Formik
        editable: roleSelection, // Make editor editable based on roleSelection

        onUpdate: ({ editor }) => {
            setFieldValue('description', editor.getHTML()); // Syncs the HTML content with Formik
        },
    });
    // useEffect(() => {
    //     if (editor && values.description) {
    //         editor.commands.setContent(values.description);
    //     }
    // }, [editor, values.description]);

    const debouncedUpdate = useCallback(
        debounce((content) => {
            setFieldValue('description', content);
        }, 300),
        [] // Dependencies can be adjusted based on your needs
    );

    useEffect(() => {
        if (editor) {
            editor.on('update', ({ editor }) => {
                const html = editor.getHTML();
                setEditorContent(html);
                debouncedUpdate(html);
            });
        }
    }, [editor, debouncedUpdate]);

    useEffect(() => {
        if (editor && values.description !== editorContent) {
            editor.commands.setContent(values.description);
        }
    }, [editor, values.description, editorContent]);

    const handleKeyPress = (event: any) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    return (
        <form
            className="h-auto bg-[#F6F7F8]"
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
        >
            <div className="w-11/12 mx-auto">
                <div className="w-full mt-10 sticky top-0 z-[1] bg-[#F6F7F8] py-4">
                    <div className="w-11/12 mx-auto">
                        <h1 className="text-[13px] font-[400]">
                            <span
                                className="hover:text-[#085BEB] cursor-pointer"
                                onClick={() =>
                                    navigate(
                                        `${
                                            roleSelection
                                                ? '/request/mycourse'
                                                : '/course/search'
                                        }`
                                    )
                                }
                            >
                                {roleSelection ? 'My Courses' : 'Search'}
                            </span>
                            <span
                                className={`${!roleSelection && 'ml-2 mr-2'}`}
                            >
                                {!roleSelection && '>'}
                            </span>
                            <span>{!roleSelection && formik.values.title}</span>
                            <span
                                className={`${!roleSelection && 'ml-2 mr-2'}`}
                            >
                                {!roleSelection && '>'}
                            </span>
                            <span
                                className="hover:text-[#085BEB] cursor-pointer"
                                onClick={() =>
                                    navigate(
                                        `/course/view/${values.title}/${id}`
                                    )
                                }
                            >
                                {!roleSelection && 'My Courses'}
                            </span>
                            <span className="ml-2 mr-2">{'>'}</span>
                            {roleSelection
                                ? id
                                    ? 'Edit Course Details'
                                    : 'Add New Course'
                                : 'Customize Request'}
                        </h1>
                        <div>
                            <div className="flex justify-between mt-6">
                                <h1 className="ml-1 text-[25px] font-[600] capitalize">
                                    {roleSelection
                                        ? formik.values.title && id
                                            ? formik.values.title
                                            : 'Add New Course'
                                        : 'Customize Request'}
                                </h1>
                                {/* <div className="flex items-center justify-center text-center"> */}
                                {/* {(id)&&<p className={`bg-[#F8F8F8] border-[#065FEF] border-2 rounded-[4px] w-[88px] h-[42px] text-[#065FEF] text-[13px] font-[400] cursor-pointer`} onClick={()=>navigate(`course/view/${id}`)}>Back</p>} */}
                                {loading ? (
                                    <div className="bg-[#065FEF] rounded-[4px] w-[88px] h-[42px] flex justify-center items-center">
                                        <PulseLoader color="#ffffff" size={8} />
                                    </div>
                                ) : (
                                    <button
                                        className={`bg-[#065FEF] rounded-[4px] w-[88px] h-[42px] text-white text-[13px] font-[400]
                                          disabled:opacity-50
                                      `}
                                        type="submit"
                                        ref={submitButtonRef}
                                        disabled={keyObject}
                                    >
                                        {roleSelection ? 'Save' : 'Send'}
                                    </button>
                                )}
                                {/* </div> */}
                            </div>
                            <div className="border-b-2 mt-2"></div>
                        </div>
                    </div>
                </div>
                <div className="w-11/12 mx-auto mt-10">
                    <div className="mt-10">
                        <InputTag
                            focus={'focus'}
                            name="title"
                            label={'Title'}
                            disabled={!roleSelection && true}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.title}
                            isTouched={touched.title}
                            className={`h-[55px] mt-4 ${
                                !roleSelection && 'opacity-50'
                            }`}
                        />

                        {/* <InputTag
                          name="description"
                          label={'Description'}
                          disabled={!roleSelection}
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.description}
                          isTouched={touched.description}
                          className={`h-[170px] mt-4 rounded-[8px] p-2 px-4 bg-white disabled:opacity-50`}
                      /> */}

                        {/* 
                        <div className="flex flex-col mb-12 relative">
                            <label className="text-[15px] font-[500]">
                                Description
                                <span className={`ml-0.5 text-red-600`}>*</span>
                            </label>
                            <textarea
                                autoCorrect="false"
                                resize-none
                                disabled={!roleSelection}
                                className={`h-[170px] mt-4 rounded-[8px] p-2 px-4 bg-white disabled:opacity-50 ${
                                    !roleSelection && 'opacity-50'
                                } bg-white rounded-[8px] px-4 border-[0.7px] border-black`}
                                value={values.description}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                name="description"
                            >
                                {values.description}
                            </textarea>
                            {errors.description && touched.description && (
                                <p
                                    className={`text-red-500 text-[12px] w-full text-end mt-1 absolute`}
                                >
                                    {errorValue}
                                </p>
                            )}
                        </div>

 */}

                        <div className="flex flex-col mb-12 relative">
                            <label className="text-sm font-medium">
                                Description
                                <span className="ml-1 text-red-600">*</span>
                            </label>
                            <div
                                className={`bg-white p-4 rounded-lg border border-black mt-4 ${
                                    !roleSelection
                                        ? 'opacity-50  text-[#7b7b7c] font-light'
                                        : ''
                                }`}
                            >
                                {editor && (
                                    <div>
                                        <MenuBar
                                            editor={editor}
                                            disabled={!roleSelection}
                                        />
                                        <EditorContent
                                            editor={editor}
                                            className="outline-none p-2 h-full w-full overflow-y-auto break-words "
                                        />
                                    </div>
                                )}
                                {typeof errors.description === 'string' &&
                                    touched.description && (
                                        <p className="text-red-500 text-xs absolute right-2 bottom-2">
                                            {errors.description}
                                        </p>
                                    )}
                            </div>
                        </div>

                        {!isCustomizeCourse && (
                            <Tags
                                tags={values.tags}
                                setFieldValue={setFieldValue}
                                values={values}
                                error={errors.tags && touched.tags}
                                onBlur={handleBlur}
                                formik={formik}
                            />
                        )}
                    </div>
                    <DayField
                        id="session"
                        title={'Days'}
                        formik={formik}
                        value={values.session}
                        onChange={(e: any) => {
                            handleChange(e);
                            DayData(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.session}
                        isTouched={touched.session}
                    />
                    <div className="flex w-full justify-between gap-12">
                        <div className="flex gap-4 w-1/2 justify-between">
                            <InputTag
                                name="duration_count"
                                label={'Duration'}
                                className={`h-[55px] mt-4 numberClass`}
                                type="number"
                                value={values.duration_count}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.duration_count}
                                isTouched={touched.duration_count}
                                Tagwidth={'w-1/2'}
                                onKeyPress={handleKeyPress} // Restrict input to digits only
                                placeholder="eg. 10"
                            />
                            <DurationLength
                                name="duration_type"
                                onChange={(e: any) => handleDate(e)}
                                value={values.duration_type}
                                check={values.duration_count}
                            />
                        </div>
                        <div className="flex flex-col w-1/2">
                            <label className="text-[15px] font-[500]">
                                Mode
                                <span className="ml-0.5 text-red-600">*</span>
                            </label>
                            <div className="flex justify-between mt-4 gap-12">
                                <RadioInput
                                    name="mode"
                                    type={'radio'}
                                    label={'Offline'}
                                    checked={values.mode === 'offline'}
                                    className={'h-[55px] rounded-[8px]'}
                                    onChange={() =>
                                        onChangeInputValue('mode', 'offline')
                                    }
                                />
                                <RadioInput
                                    name="mode"
                                    type={'radio'}
                                    label={'Online'}
                                    checked={values.mode === 'online'}
                                    className={'h-[55px] rounded-[8px]'}
                                    onChange={() =>
                                        onChangeInputValue('mode', 'online')
                                    }
                                />
                                <RadioInput
                                    name="mode"
                                    type={'radio'}
                                    label={'Both'}
                                    checked={values.mode === 'both'}
                                    className={'h-[55px] rounded-[8px]'}
                                    onChange={() =>
                                        onChangeInputValue('mode', 'both')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <DateAndBatchTimings
                        dates={values.batches}
                        setFieldValue={setFieldValue}
                        isCustomizeCourse={isCustomizeCourse}
                        isEdit={Boolean(id)}
                        batch={values.batch}
                        error={errors.batch}
                        roleSelection={roleSelection}
                    />
                    {/* <BatchTimingLine formik={formik} isCustomizeCourse={isCustomizeCourse} /> */}
                    {roleSelection && (
                        <div className="flex justify-between gap-12 mb-6">
                            <FieldsLeft
                                role={role}
                                deleteUploadedImage={deleteUploadedImage}
                                code={input['currency_code']}
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                //   addCourse={addCourse}
                                onChangeInputValue={onChangeInputValue}
                                resumeStatus={resumeStatus}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                                previewUrl={previewUrl}
                                uploadResumeFile={uploadResumeFile}
                                setResumeStatus={setResumeStatus}
                                imageKeyURL={values.image}
                            />
                            <FieldsRight
                                role={role}
                                formik={formik}
                                disabled={!values.discount_available}
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                //   addCourse={addCourse}
                                onChangeInputValue={onChangeInputValue}
                                uploadResumeFile={uploadResumeFile}
                                previewUrl={previewUrl}
                                setPreviewURL={setPreviewURL}
                                roleSelection={roleSelection}
                                placeholder="dsdsds"
                            />
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};

// interface MenuBarProps {
//     editor: Editor | null;
// }

// const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
//     if (!editor) {
//         return null;
//     }

//     return (
//         <div className="menuBar ">
//             <div>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleBold().run()}
//                     className={editor.isActive('bold') ? 'is_active' : ''}
//                 >
//                     <FaBold />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleItalic().run()}
//                     className={editor.isActive('italic') ? 'is_active' : ''}
//                 >
//                     <FaItalic />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleUnderline().run()
//                     }
//                     className={editor.isActive('underline') ? 'is_active' : ''}
//                 >
//                     <FaUnderline />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleStrike().run()}
//                     className={editor.isActive('strike') ? 'is_active' : ''}
//                 >
//                     <FaStrikethrough />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleHeading({ level: 2 }).run()
//                     }
//                     className={
//                         editor.isActive('heading', { level: 2 })
//                             ? 'is_active'
//                             : ''
//                     }
//                 >
//                     <FaHeading />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleHeading({ level: 3 }).run()
//                     }
//                     className={
//                         editor.isActive('heading', { level: 3 })
//                             ? 'is_active'
//                             : ''
//                     }
//                 >
//                     <FaHeading className="heading3" />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleBulletList().run()
//                     }
//                     className={editor.isActive('bulletList') ? 'is_active' : ''}
//                 >
//                     <FaListUl />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleOrderedList().run()
//                     }
//                     className={
//                         editor.isActive('orderedList') ? 'is_active' : ''
//                     }
//                 >
//                     <FaListOl />
//                 </button>
//                 {/* <button
//                 onClick={() =>
//                     editor.chain().focus().toggleBlockquote().run()
//                 }
//                 className={editor.isActive('blockquote') ? 'is_active' : ''}
//             >
//                 <FaQuoteLeft />
//             </button> */}
//             </div>
//             <div>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().undo().run()}
//                 >
//                     <FaUndo />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().redo().run()}
//                 >
//                     <FaRedo />
//                 </button>
//             </div>
//         </div>
//     );
// };

interface MenuBarProps {
    editor: Editor | null;
    disabled: boolean; // Add this line
}

const MenuBar: React.FC<MenuBarProps> = ({ editor, disabled }) => {
    if (!editor || disabled) {
        return null;
    }

    return (
        <div className="menuBar ">
            <div>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is_active' : ''}
                    disabled={disabled} // Add this line
                >
                    <FaBold />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is_active' : ''}
                    disabled={disabled} // Add this line
                >
                    <FaItalic />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleUnderline().run()
                    }
                    className={editor.isActive('underline') ? 'is_active' : ''}
                    disabled={disabled} // Add this line
                >
                    <FaUnderline />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={editor.isActive('strike') ? 'is_active' : ''}
                    disabled={disabled} // Add this line
                >
                    <FaStrikethrough />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    className={
                        editor.isActive('heading', { level: 2 })
                            ? 'is_active'
                            : ''
                    }
                    disabled={disabled} // Add this line
                >
                    <FaHeading />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    className={
                        editor.isActive('heading', { level: 3 })
                            ? 'is_active'
                            : ''
                    }
                    disabled={disabled} // Add this line
                >
                    <FaHeading className="heading3" />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleBulletList().run()
                    }
                    className={editor.isActive('bulletList') ? 'is_active' : ''}
                    disabled={disabled} // Add this line
                >
                    <FaListUl />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleOrderedList().run()
                    }
                    className={
                        editor.isActive('orderedList') ? 'is_active' : ''
                    }
                    disabled={disabled} // Add this line
                >
                    <FaListOl />
                </button>
                {/* <button
                onClick={() =>
                    editor.chain().focus().toggleBlockquote().run()
                }
                className={editor.isActive('blockquote') ? 'is_active' : ''}
                disabled={disabled} // Add this line
            >
                <FaQuoteLeft />
            </button> */}
            </div>
            <div>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={disabled} // Add this line
                >
                    <FaUndo />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={disabled} // Add this line
                >
                    <FaRedo />
                </button>
            </div>
        </div>
    );
};
